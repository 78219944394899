// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProductimageexporter.proto" (package "grpcProductimageexporter", syntax proto3)
// tslint:disable
import { BoolValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcProductimageexporter.Input
 */
export interface Input {
    /**
     * @generated from protobuf field: string Id = 1 [json_name = "Id"];
     */
    Id: string;
    /**
     * @generated from protobuf field: string Date = 2 [json_name = "Date"];
     */
    Date: string;
    /**
     * @generated from protobuf field: string Title = 3 [json_name = "Title"];
     */
    Title: string;
    /**
     * @generated from protobuf field: string TaskId = 4 [json_name = "TaskId"];
     */
    TaskId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Input$Type extends MessageType<Input> {
    constructor() {
        super("grpcProductimageexporter.Input", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Date", kind: "scalar", localName: "Date", jsonName: "Date", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Title", kind: "scalar", localName: "Title", jsonName: "Title", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Input>): Input {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = "";
        message.Date = "";
        message.Title = "";
        message.TaskId = "";
        if (value !== undefined)
            reflectionMergePartial<Input>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Input): Input {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.string();
                    break;
                case /* string Date = 2 [json_name = "Date"];*/ 2:
                    message.Date = reader.string();
                    break;
                case /* string Title = 3 [json_name = "Title"];*/ 3:
                    message.Title = reader.string();
                    break;
                case /* string TaskId = 4 [json_name = "TaskId"];*/ 4:
                    message.TaskId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Input, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Id = 1 [json_name = "Id"]; */
        if (message.Id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Id);
        /* string Date = 2 [json_name = "Date"]; */
        if (message.Date !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Date);
        /* string Title = 3 [json_name = "Title"]; */
        if (message.Title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Title);
        /* string TaskId = 4 [json_name = "TaskId"]; */
        if (message.TaskId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.TaskId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProductimageexporter.Input
 */
export const Input = new Input$Type();
/**
 * @generated ServiceType for protobuf service grpcProductimageexporter.ProductImageExporterService
 */
export const ProductImageExporterService = new ServiceType("grpcProductimageexporter.ProductImageExporterService", [
    { name: "AddToQueue", options: {}, I: Input, O: BoolValue }
]);
