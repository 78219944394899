import { GrpcManager, RecconectableStream, Types } from "grpc-connect";
import { useEffect, useRef, useState } from "react";
import { LoadingIndicatorFlat, Modal, RelativePositionPresets } from "serenity-controls";
import styles from "./QueueMonitor.module.sass";
import { TaskDetails } from "./TaskDetails";
import { TaskMonitor } from "./TaskMonitor";
import { Info } from "lucide-react";

const client = GrpcManager.QueueManager.getQueueService();
type TaskInfo = Types.QueueManager.QueueService.TaskInfo;

interface TaskData {
    taskInfo: TaskInfo;
    inputData: object & {
        __metadata?: {
            [key: string]: unknown;
        };
    };
    index: number;
}

const QueueMonitor = ({
    name,
    taskTitle,
    title,
    onTasksChanged,
    showActiveProgress,
}: {
    name: string;
    title?: string;
    taskTitle?: (data: TaskData) => string;
    onTasksChanged?: (tasks: TaskInfo[]) => void;
    showActiveProgress?: boolean;
}) => {
    const container = useRef<HTMLDivElement>(null);
    const [tasks, setTasks] = useState<TaskInfo[] | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const [taskPreview, setCurrTaskPreview] = useState<{
        taskInfo: TaskInfo;
        taskInput: unknown;
        title: string;
    } | null>(null);

    const [activeTasks, setActiveTasks] = useState<TaskInfo[]>([]);

    useEffect(() => {
        setActiveTasks(tasks?.filter((t) => t.State == 1) || []);
    }, [tasks]);

    useEffect(() => {
        const abort = new AbortController();
        const stream = new RecconectableStream(() => {
            return client.getTasksListStream(
                {
                    Queue: name,
                    Type: ["Active", "Pending", "Completed", "Retry"],
                    Offset: 0,
                },
                { abort: abort.signal },
            );
        });

        stream.onMessage((message) => {
            setTasks(message.Tasks);
            if (onTasksChanged)
                onTasksChanged(
                    message.Tasks.map((t) => ({
                        ...t,
                        Payload: JSON.parse(atob(t.Payload)),
                    })),
                );
        });

        return () => {
            abort.abort();
        };
    }, []);

    return (
        <div className={styles.main + " " + (expanded && styles.expanded)} ref={container}>
            <div className={styles.infoContainer} onClick={() => setExpanded((e) => !e)}>
                <span className={styles.info}>
                    {title && (
                        <div>
                            <b>{title}</b>
                        </div>
                    )}
                    {tasks && tasks.filter((t) => t.State == 1).length > 0 && (
                        <>
                            <div>
                                {/* <Status status={1} /> */}
                                <LoadingIndicatorFlat />
                            </div>
                            <div>{tasks?.filter((t) => t.State == 1).length || 0}</div>
                        </>
                    )}
                    {tasks && tasks.filter((t) => t.State == 2).length > 0 && (
                        <>
                            <div>
                                <Status status={2} />
                            </div>
                            <div>{tasks?.filter((t) => t.State == 2).length || 0}</div>
                        </>
                    )}
                    {tasks && tasks.filter((t) => t.State == 6).length > 0 && (
                        <>
                            <div>
                                <Status status={6} />
                            </div>
                            <div>{tasks?.filter((t) => t.State == 6).length || 0}</div>
                        </>
                    )}
                    {tasks && tasks.filter((t) => t.State == 4).length > 0 && (
                        <>
                            <div>
                                <Status status={4} />
                            </div>
                            <div>{tasks?.filter((t) => t.State == 4).length || 0}</div>
                        </>
                    )}
                </span>
            </div>

            {showActiveProgress && (
                <div>
                    {activeTasks.map((t) => {
                        return (
                            <div key={t.ID} style={{ padding: 5 }}>
                                <div
                                    style={{
                                        fontSize: 10,
                                        backgroundColor: "lightgrey",
                                        padding: 3,
                                        paddingLeft: 10,
                                        borderRadius: 4,
                                    }}
                                >
                                    {taskTitle
                                        ? taskTitle({
                                              taskInfo: t,
                                              inputData: JSON.parse(atob(t.Payload)),
                                              index: 0,
                                          })
                                        : t.ID}
                                </div>
                                <TaskMonitor taskId={t.ID} queue={name} />
                            </div>
                        );
                    })}
                </div>
            )}

            {expanded && (
                <Modal
                    show={true}
                    hideOnBlur={true}
                    onHide={() => setExpanded(false)}
                    shadow={false}
                    layer={false}
                    relativeTo={() => container.current}
                    relativeSettings={{
                        ...RelativePositionPresets.bottomLeft,
                        widthCalc: "min",
                    }}
                >
                    <div className={styles.expandedContainer}>
                        <div>
                            {(!tasks || tasks.length == 0) && <div style={{ padding: 10, textAlign: "center" }}>Brak zadań</div>}
                            {tasks?.map((task, index) => {
                                const TaskTitle = taskTitle
                                    ? taskTitle({
                                          taskInfo: task,
                                          inputData: JSON.parse(atob(task.Payload)),
                                          index,
                                      })
                                    : task.ID;
                                return (
                                    <div
                                        key={task.ID}
                                        className={styles.task}
                                        onClick={() => {
                                            setCurrTaskPreview({
                                                taskInfo: task,
                                                taskInput: JSON.parse(atob(task.Payload)),
                                                title: TaskTitle,
                                            });
                                        }}
                                    >
                                        <div style={{ margin: 5 }}>
                                            <Status status={task.State} />
                                        </div>
                                        <div>{TaskTitle}</div>

                                        <div>
                                            {task.LastErr && (
                                                <a title={task.LastErr} style={{ color: "darkred", marginLeft: 10 }}>
                                                    <Info />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal>
            )}
            {taskPreview && (
                <Modal show={true} onHide={() => setCurrTaskPreview(null)} showHideLink={true} title={taskPreview.title}>
                    <TaskDetails task={taskPreview.taskInfo} />
                </Modal>
            )}
        </div>
    );
};

const colors = [
    "",
    "pink", // active
    "yellow", // pending
    "orange", // running
    "darkred", // repeated
    "purple",
    "green",
    "brown",
    "pink",
];

const Status = ({ status }: { status: number }) => {
    return (
        <div
            style={{
                backgroundColor: colors[status],
                height: 10,
                width: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
            }}
        >
            {" "}
        </div>
    );
};
///wkluczenia newsletter

export { QueueMonitor };
