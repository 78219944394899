// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTodo_service.proto" (package "grpcTodo_service", syntax proto3)
// tslint:disable
import { Int32Value } from "./google/protobuf/wrappers";
import { StringValue } from "./google/protobuf/wrappers";
import { Empty } from "./google/protobuf/empty";
import { BoolValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcTodo_service.MapStringStringValue
 */
export interface MapStringStringValue {
    /**
     * @generated from protobuf field: map<string, string> Value = 1 [json_name = "Value"];
     */
    Value: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message grpcTodo_service.ListGetListsRowValue
 */
export interface ListGetListsRowValue {
    /**
     * @generated from protobuf field: repeated grpcTodo_service.GetListsRow Value = 1 [json_name = "Value"];
     */
    Value: GetListsRow[];
}
/**
 * @generated from protobuf message grpcTodo_service.GetListsRow
 */
export interface GetListsRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Name = 2 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: int64 TasksCount = 3 [json_name = "TasksCount"];
     */
    TasksCount: number;
}
/**
 * @generated from protobuf message grpcTodo_service.NullString
 */
export interface NullString {
    /**
     * @generated from protobuf field: string String = 1 [json_name = "String"];
     */
    String: string;
    /**
     * @generated from protobuf field: bool Valid = 2 [json_name = "Valid"];
     */
    Valid: boolean;
}
/**
 * @generated from protobuf message grpcTodo_service.ListGetTodoTasksRowValue
 */
export interface ListGetTodoTasksRowValue {
    /**
     * @generated from protobuf field: repeated grpcTodo_service.GetTodoTasksRow Value = 1 [json_name = "Value"];
     */
    Value: GetTodoTasksRow[];
}
/**
 * @generated from protobuf message grpcTodo_service.GetTodoTasksRow
 */
export interface GetTodoTasksRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: int64 Sort = 2 [json_name = "Sort"];
     */
    Sort: number;
    /**
     * @generated from protobuf field: string Topic = 3 [json_name = "Topic"];
     */
    Topic: string;
    /**
     * @generated from protobuf field: int64 Priority = 4 [json_name = "Priority"];
     */
    Priority: number;
    /**
     * @generated from protobuf field: grpcTodo_service.NullString OwnerLogin = 5 [json_name = "OwnerLogin"];
     */
    OwnerLogin?: NullString;
    /**
     * @generated from protobuf field: grpcTodo_service.NullString AssignedLogin = 6 [json_name = "AssignedLogin"];
     */
    AssignedLogin?: NullString;
    /**
     * @generated from protobuf field: int64 ListID = 7 [json_name = "ListID"];
     */
    ListID: number;
    /**
     * @generated from protobuf field: int64 Done = 8 [json_name = "Done"];
     */
    Done: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class MapStringStringValue$Type extends MessageType<MapStringStringValue> {
    constructor() {
        super("grpcTodo_service.MapStringStringValue", [
            { no: 1, name: "Value", kind: "map", localName: "Value", jsonName: "Value", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<MapStringStringValue>): MapStringStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = {};
        if (value !== undefined)
            reflectionMergePartial<MapStringStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapStringStringValue): MapStringStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> Value = 1 [json_name = "Value"];*/ 1:
                    this.binaryReadMap1(message.Value, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: MapStringStringValue["Value"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MapStringStringValue["Value"] | undefined, val: MapStringStringValue["Value"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field grpcTodo_service.MapStringStringValue.Value");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: MapStringStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> Value = 1 [json_name = "Value"]; */
        for (let k of globalThis.Object.keys(message.Value))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.Value[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.MapStringStringValue
 */
export const MapStringStringValue = new MapStringStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetListsRowValue$Type extends MessageType<ListGetListsRowValue> {
    constructor() {
        super("grpcTodo_service.ListGetListsRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetListsRow }
        ]);
    }
    create(value?: PartialMessage<ListGetListsRowValue>): ListGetListsRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetListsRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetListsRowValue): ListGetListsRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo_service.GetListsRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetListsRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetListsRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo_service.GetListsRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetListsRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.ListGetListsRowValue
 */
export const ListGetListsRowValue = new ListGetListsRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetListsRow$Type extends MessageType<GetListsRow> {
    constructor() {
        super("grpcTodo_service.GetListsRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "TasksCount", kind: "scalar", localName: "TasksCount", jsonName: "TasksCount", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetListsRow>): GetListsRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Name = "";
        message.TasksCount = 0;
        if (value !== undefined)
            reflectionMergePartial<GetListsRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetListsRow): GetListsRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Name = 2 [json_name = "Name"];*/ 2:
                    message.Name = reader.string();
                    break;
                case /* int64 TasksCount = 3 [json_name = "TasksCount"];*/ 3:
                    message.TasksCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetListsRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Name = 2 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Name);
        /* int64 TasksCount = 3 [json_name = "TasksCount"]; */
        if (message.TasksCount !== 0)
            writer.tag(3, WireType.Varint).int64(message.TasksCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.GetListsRow
 */
export const GetListsRow = new GetListsRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NullString$Type extends MessageType<NullString> {
    constructor() {
        super("grpcTodo_service.NullString", [
            { no: 1, name: "String", kind: "scalar", localName: "String", jsonName: "String", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Valid", kind: "scalar", localName: "Valid", jsonName: "Valid", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NullString>): NullString {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.String = "";
        message.Valid = false;
        if (value !== undefined)
            reflectionMergePartial<NullString>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NullString): NullString {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string String = 1 [json_name = "String"];*/ 1:
                    message.String = reader.string();
                    break;
                case /* bool Valid = 2 [json_name = "Valid"];*/ 2:
                    message.Valid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NullString, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string String = 1 [json_name = "String"]; */
        if (message.String !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.String);
        /* bool Valid = 2 [json_name = "Valid"]; */
        if (message.Valid !== false)
            writer.tag(2, WireType.Varint).bool(message.Valid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.NullString
 */
export const NullString = new NullString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetTodoTasksRowValue$Type extends MessageType<ListGetTodoTasksRowValue> {
    constructor() {
        super("grpcTodo_service.ListGetTodoTasksRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetTodoTasksRow }
        ]);
    }
    create(value?: PartialMessage<ListGetTodoTasksRowValue>): ListGetTodoTasksRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetTodoTasksRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetTodoTasksRowValue): ListGetTodoTasksRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo_service.GetTodoTasksRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetTodoTasksRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetTodoTasksRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo_service.GetTodoTasksRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetTodoTasksRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.ListGetTodoTasksRowValue
 */
export const ListGetTodoTasksRowValue = new ListGetTodoTasksRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTodoTasksRow$Type extends MessageType<GetTodoTasksRow> {
    constructor() {
        super("grpcTodo_service.GetTodoTasksRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Topic", kind: "scalar", localName: "Topic", jsonName: "Topic", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Priority", kind: "scalar", localName: "Priority", jsonName: "Priority", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "OwnerLogin", kind: "message", localName: "OwnerLogin", jsonName: "OwnerLogin", T: () => NullString },
            { no: 6, name: "AssignedLogin", kind: "message", localName: "AssignedLogin", jsonName: "AssignedLogin", T: () => NullString },
            { no: 7, name: "ListID", kind: "scalar", localName: "ListID", jsonName: "ListID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "Done", kind: "scalar", localName: "Done", jsonName: "Done", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTodoTasksRow>): GetTodoTasksRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Sort = 0;
        message.Topic = "";
        message.Priority = 0;
        message.ListID = 0;
        message.Done = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTodoTasksRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTodoTasksRow): GetTodoTasksRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* int64 Sort = 2 [json_name = "Sort"];*/ 2:
                    message.Sort = reader.int64().toNumber();
                    break;
                case /* string Topic = 3 [json_name = "Topic"];*/ 3:
                    message.Topic = reader.string();
                    break;
                case /* int64 Priority = 4 [json_name = "Priority"];*/ 4:
                    message.Priority = reader.int64().toNumber();
                    break;
                case /* grpcTodo_service.NullString OwnerLogin = 5 [json_name = "OwnerLogin"];*/ 5:
                    message.OwnerLogin = NullString.internalBinaryRead(reader, reader.uint32(), options, message.OwnerLogin);
                    break;
                case /* grpcTodo_service.NullString AssignedLogin = 6 [json_name = "AssignedLogin"];*/ 6:
                    message.AssignedLogin = NullString.internalBinaryRead(reader, reader.uint32(), options, message.AssignedLogin);
                    break;
                case /* int64 ListID = 7 [json_name = "ListID"];*/ 7:
                    message.ListID = reader.int64().toNumber();
                    break;
                case /* int64 Done = 8 [json_name = "Done"];*/ 8:
                    message.Done = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTodoTasksRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* int64 Sort = 2 [json_name = "Sort"]; */
        if (message.Sort !== 0)
            writer.tag(2, WireType.Varint).int64(message.Sort);
        /* string Topic = 3 [json_name = "Topic"]; */
        if (message.Topic !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Topic);
        /* int64 Priority = 4 [json_name = "Priority"]; */
        if (message.Priority !== 0)
            writer.tag(4, WireType.Varint).int64(message.Priority);
        /* grpcTodo_service.NullString OwnerLogin = 5 [json_name = "OwnerLogin"]; */
        if (message.OwnerLogin)
            NullString.internalBinaryWrite(message.OwnerLogin, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* grpcTodo_service.NullString AssignedLogin = 6 [json_name = "AssignedLogin"]; */
        if (message.AssignedLogin)
            NullString.internalBinaryWrite(message.AssignedLogin, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int64 ListID = 7 [json_name = "ListID"]; */
        if (message.ListID !== 0)
            writer.tag(7, WireType.Varint).int64(message.ListID);
        /* int64 Done = 8 [json_name = "Done"]; */
        if (message.Done !== 0)
            writer.tag(8, WireType.Varint).int64(message.Done);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo_service.GetTodoTasksRow
 */
export const GetTodoTasksRow = new GetTodoTasksRow$Type();
/**
 * @generated ServiceType for protobuf service grpcTodo_service.TodoService
 */
export const TodoService = new ServiceType("grpcTodo_service.TodoService", [
    { name: "GetLists", options: {}, I: BoolValue, O: ListGetListsRowValue },
    { name: "SuperMethod233_aaa1223465", options: {}, I: BoolValue, O: BoolValue },
    { name: "GetLists5", options: {}, I: BoolValue, O: BoolValue },
    { name: "Create", options: {}, I: Empty, O: StringValue },
    { name: "GetTasks", options: {}, I: Int32Value, O: ListGetTodoTasksRowValue },
    { name: "GetMainUsers1", options: {}, I: Empty, O: MapStringStringValue }
]);
