import { Getter, atom } from "jotai";
import { atomWithDefault, atomWithStorage } from "jotai/utils";

export const counter = atomWithStorage("jontai.counter", false);
import { GrpcManager } from "grpc-connect";
import type { Types } from "grpc-connect";
import { PrimitiveAtom, WritableAtom } from "jotai";
export const client: ReturnType<typeof GrpcManager.QueueManager.getQueueService> = GrpcManager.QueueManager.getQueueService();

type ListPointerQueueInfoValue = Types.QueueManager.QueueService.ListPointerQueueInfoValue;
type QueueServerInfo = Types.QueueManager.QueueService.QueueServerInfo;

export const serverInfo: WritableAtom<Promise<ListPointerQueueInfoValue>, [], Promise<void>> = atomWithRefresh<Promise<ListPointerQueueInfoValue>>(async () => {
    return (await client.getQueueServerInfo({})).response;
});

export function atomWithRefresh<T>(fn: (get: Getter) => T) {
    const initial = atomWithDefault(fn);
    const thisAtom = atom(
        (get) => get(initial),
        async (get, set) => {
            const next = await fn(get);
            set(initial, next);
        },
    );
    return thisAtom;
}

type QInfo = {
    queue: string;
    state: string;
    offset: number;
};

export const preview = atom<null | QInfo>(null);

// export const tasksInfo = atomWithRefresh<
//   Promise<undefined | ListPointerTaskInfoValue>
// >(async (get) => {
//   console.log("jedna dupa");
//   const queryData = get(preview);
//   if (queryData === null) {
//     console.log("pół dupa");
//     return undefined;
//   }
//   console.log("druga dupa");

//   console.log(queryData);
//   const result = (
//     await client.getTasksList({
//       Queue: queryData.queue,
//       Type: queryData.state,
//       Offset: queryData.offset,
//     })
//   ).response;
//   console.log("trzecia dupa");
//   console.log(result);

//   return result;
// });

// export const serverInfo = atomWithRefresh<Promise<ListPointerQueueInfoValue>>(
//   async () => {
//     return (await client.getQueueServerInfo({})).response;
//   }
// );

export const queuesInfo: PrimitiveAtom<QueueServerInfo> = atom<QueueServerInfo>({
    Queues: [],
});

export const _queuesInfo: WritableAtom<QueueServerInfo, unknown[], void> = atom<QueueServerInfo, unknown[], void>(
    (get): QueueServerInfo => {
        return get(_queuesInfo);
    },
    (get, set) => {
        const abort = new AbortController();
        const call = client.getQueueServerInfoStream({ value: 1 }, { abort: abort.signal });

        call.responses.onMessage((message) => {
            set(queuesInfo, message);
        });

        return () => {
            abort.abort();
        };
    },
);
