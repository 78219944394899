import * as React from "react";

import { BsChevronDoubleRight } from "@react-icons/all-files/bs/BsChevronDoubleRight";
import { HiOutlineChevronDoubleLeft } from "@react-icons/all-files/hi/HiOutlineChevronDoubleLeft";
import "./SubMenu.sass";

import { IMenuElement, IMenuSection } from "./types";
import { Translation } from "../../translation";

interface IMenuProps {
    elements: IMenuSection[];
    onMenuElementClick: (element: IMenuElement, inWindow?: boolean) => unknown;
    mobile: boolean;
}

interface IMenuState {
    currentMenuOpened: number;
    expanded: boolean;
}

export class SubMenu extends React.Component<IMenuProps, IMenuState> {
    constructor(props: IMenuProps) {
        super(props);

        const expanded = window.localStorage.backofficeMenuOpened !== undefined ? window.localStorage.backofficeMenuOpened == "1" : props.mobile;

        let currOpened = -1;
        if (!props.mobile && expanded) {
            currOpened = parseInt(window.localStorage.getItem("backofficeMenuOpenedIndex") ?? "-1");
        }

        this.state = {
            currentMenuOpened: currOpened,
            expanded: expanded,
        };
    }

    public handleTitleEnter = (index: number) => {
        window.localStorage.setItem("backofficeMenuOpenedIndex", index.toString());
        if (!this.state.expanded) {
            this.setState({ currentMenuOpened: index });
        }
    };

    public handleMenuLeave = () => {
        if (!this.state.expanded) {
            this.setState({ currentMenuOpened: -1 });
        }
    };

    public handleElementClickOpen(el: IMenuElement, event: React.MouseEvent) {
        event.stopPropagation();
        this.props.onMenuElementClick(el, true);
    }

    public handleElementClick(el: IMenuElement) {
        this.props.onMenuElementClick(el);
    }

    public changeExpandState = () => {
        // @ts-ignore Need to change
        window.localStorage.backofficeMenuOpened = this.state.expanded ? "0" : "1";
        const newVal = !this.state.expanded;
        this.setState({ expanded: newVal });
        if (!newVal) {
            this.setState({ currentMenuOpened: -1 });
        }
    };

    public render() {
        let style = {};
        if (this.props.mobile) {
            style = {
                position: "absolute",
                top: 50,
                bottom: 0,
                zIndex: 100,
            };
        }
        return (
            <div className={"w-menu " + (this.state.expanded ? "w-menu-expanded" : "w-menu-collapsed")} onMouseLeave={this.handleMenuLeave} style={style}>
                {this.props.elements.map((el, index) => {
                    return (
                        <div className="menu-section" key={index}>
                            <div className="menu-section-title" onClick={() => this.setState({ currentMenuOpened: index })} onMouseEnter={() => this.handleTitleEnter(index)}>
                                {el.icon ? <el.icon /> : null}
                                <span>
                                    <Translation>{el.title}</Translation>
                                </span>
                            </div>
                            <div className={"menu-section-section menu-section-section-" + (index == this.state.currentMenuOpened ? "opened" : "closed")}>
                                {!this.state.expanded && <div className="section-inner-title">{el.title}</div>}
                                {index == this.state.currentMenuOpened &&
                                    el.elements.map((subelement) => {
                                        return (
                                            <div key={subelement.title} className="menu-link" onClick={() => this.handleElementClick(subelement)}>
                                                {/*className={'menu-link-title-icon'}*/}
                                                <Translation>{subelement.title}</Translation>
                                                <div onClick={(event) => this.handleElementClickOpen(subelement, event)} className={"menu-link-open-window"}></div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
                {!this.props.mobile && (
                    <div className="menu-collapse " onClick={this.changeExpandState}>
                        <i>{this.state.expanded ? <HiOutlineChevronDoubleLeft /> : <BsChevronDoubleRight />}</i>
                    </div>
                )}
            </div>
        );
    }
}
