// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTodo_service.proto" (package "grpcTodo_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TodoService } from "./grpcTodo_service";
import type { MapStringStringValue } from "./grpcTodo_service";
import type { ListGetTodoTasksRowValue } from "./grpcTodo_service";
import type { Int32Value } from "./google/protobuf/wrappers";
import type { StringValue } from "./google/protobuf/wrappers";
import type { Empty } from "./google/protobuf/empty";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListGetListsRowValue } from "./grpcTodo_service";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcTodo_service.TodoService
 */
export interface ITodoServiceClient {
    /**
     * @generated from protobuf rpc: GetLists(google.protobuf.BoolValue) returns (grpcTodo_service.ListGetListsRowValue);
     */
    getLists(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, ListGetListsRowValue>;
    /**
     * @generated from protobuf rpc: SuperMethod233_aaa1223465(google.protobuf.BoolValue) returns (google.protobuf.BoolValue);
     */
    superMethod233Aaa1223465(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, BoolValue>;
    /**
     * @generated from protobuf rpc: GetLists5(google.protobuf.BoolValue) returns (google.protobuf.BoolValue);
     */
    getLists5(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, BoolValue>;
    /**
     * @generated from protobuf rpc: Create(google.protobuf.Empty) returns (google.protobuf.StringValue);
     */
    create(input: Empty, options?: RpcOptions): UnaryCall<Empty, StringValue>;
    /**
     * @generated from protobuf rpc: GetTasks(google.protobuf.Int32Value) returns (grpcTodo_service.ListGetTodoTasksRowValue);
     */
    getTasks(input: Int32Value, options?: RpcOptions): UnaryCall<Int32Value, ListGetTodoTasksRowValue>;
    /**
     * @generated from protobuf rpc: GetMainUsers1(google.protobuf.Empty) returns (grpcTodo_service.MapStringStringValue);
     */
    getMainUsers1(input: Empty, options?: RpcOptions): UnaryCall<Empty, MapStringStringValue>;
}
/**
 * @generated from protobuf service grpcTodo_service.TodoService
 */
export class TodoServiceClient implements ITodoServiceClient, ServiceInfo {
    typeName = TodoService.typeName;
    methods = TodoService.methods;
    options = TodoService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetLists(google.protobuf.BoolValue) returns (grpcTodo_service.ListGetListsRowValue);
     */
    getLists(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, ListGetListsRowValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BoolValue, ListGetListsRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SuperMethod233_aaa1223465(google.protobuf.BoolValue) returns (google.protobuf.BoolValue);
     */
    superMethod233Aaa1223465(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, BoolValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BoolValue, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLists5(google.protobuf.BoolValue) returns (google.protobuf.BoolValue);
     */
    getLists5(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, BoolValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BoolValue, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Create(google.protobuf.Empty) returns (google.protobuf.StringValue);
     */
    create(input: Empty, options?: RpcOptions): UnaryCall<Empty, StringValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, StringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTasks(google.protobuf.Int32Value) returns (grpcTodo_service.ListGetTodoTasksRowValue);
     */
    getTasks(input: Int32Value, options?: RpcOptions): UnaryCall<Int32Value, ListGetTodoTasksRowValue> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Int32Value, ListGetTodoTasksRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMainUsers1(google.protobuf.Empty) returns (grpcTodo_service.MapStringStringValue);
     */
    getMainUsers1(input: Empty, options?: RpcOptions): UnaryCall<Empty, MapStringStringValue> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, MapStringStringValue>("unary", this._transport, method, opt, input);
    }
}
