import * as React from "react";

import { CommandBar, ICommand } from "../CommandBar";
import { IconType } from "../lib/IconType";

export interface IPanelProps {
    /**
     * Panel title
     */
    title?: string;
    /**
     * Disables any padding
     */
    noPadding?: boolean;
    /**
     * Disables bottom margin
     */
    noBottomMargin?: boolean;
    /**
     * Toolbar elements
     */
    toolbar?: ICommand[];

    /**
     * Icon
     */
    icon?: IconType;

    className?: string;

    children: React.ReactNode;
}

export class Panel extends React.PureComponent<IPanelProps> {
    public static defaultProps: Partial<IPanelProps> = {
        noPadding: false,
        noBottomMargin: true,
        title: "",
        icon: undefined,
        toolbar: [],
    };

    public render() {
        const props = this.props;

        return (
            <div
                className={"bg-white border-[1px] rounded-md o  shadow-lg  " + (props.className ? props.className : "")}
            >
                {props.title && (
                    <div
                        className={
                            "flex  bg-neutral-600 p-0 items-center text-white  h-9 rounded-t-lg  heir-svg:!text-white hover:heir-a:!bg-neutral-800 pl-2"
                        }
                    >
                        {props.icon && <props.icon className="h-5 text-sm" />}
                        <div className="flex-grow pl-1 ">{props.title}</div>
                        {props.toolbar && props.toolbar.length > 0 && (
                            <div className="">
                                <CommandBar items={props.toolbar} transparentBackground={true} />
                            </div>
                        )}
                    </div>
                )}
                <div className={"overflow-auto rounded-b-md  bg-white" + (this.props.noPadding ? "" : " p-3")}>
                    {props.children}
                </div>
            </div>
        );
    }
}
