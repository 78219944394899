import { useCallback, useState } from "react";

import { AiOutlineSync } from "@react-icons/all-files/ai/AiOutlineSync";
import { CommonIcons } from "../lib/CommonIcons";
import { fI18n } from "../lib/I18n";
import "./DownloadButton.sass";
import { downloadOld, IDownloadSuccessParams } from "./Downloader";
import { ConfigKey, getEnv } from "@as-pl/env/getEnv";

interface IDownloadButtonProps {
    url: string;
    label?: string;
    downloadingLabel?: string;
    data?: any;
    onFinish?: (params: IDownloadSuccessParams) => any;
    mode?: "button" | "link" | "icon";
}

export const DownloadButton = ({
    url,
    data = {},
    label = fI18n.t("frontend:download"),
    onFinish = undefined,
    downloadingLabel = fI18n.t("frontend:downloading"),
    mode = "link",
}: IDownloadButtonProps) => {
    const [isLoading, setLoading] = useState(false);

    const downloadCallback = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        console.log(data);
        /* download(url, data).then((result) => {
            setLoading(false);
            if (onFinish !== undefined) {
                onFinish(result);
            }
        }); */

        await downloadOld(getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) + url + "?isNext=1");
        setLoading(false);
        if (onFinish !== undefined) {
            onFinish({ fileName: "" });
        }
    }, [url]);

    return (
        <div className={"w-download-button" + (isLoading ? " w-download-button-disabled" : "")}>
            <a
                className={"  w-download-button-mode-" + mode + (mode == "button" ? " btn" : "")}
                onClick={downloadCallback}
            >
                <div className={"w-download-button-icon" + (isLoading ? " w-download-button-icon-spin" : "")}>
                    {/*{isLoading && <LoadingIndicator />}*/}
                    {!isLoading ? <CommonIcons.download style={{ verticalAlign: "text-top" }} /> : <AiOutlineSync />}
                </div>
                {mode !== "icon" && (
                    <div className="w-download-button-label"> {!isLoading ? label : downloadingLabel}</div>
                )}
            </a>
        </div>
    );
};
