import * as React from "react";
import { useContext } from "react";
import { IGridFilterComponent } from "../interfaces/IGridFilter";
import { ILocale } from "../interfaces/ILocale";

export interface IGridStore {
    gridClassName: string;
    locale: ILocale;
    persistStore: {
        set: <T>(componentName: string, variableName: string, variableValue: T) => boolean;
        get: <T>(componentName: string, variableName: string) => T | null;
    };
    common: {
        icons: {
            delete: JSX.Element;
        };
        components: {
            noData: React.FC<{ communicate: string }>;
            loading: React.FC<{ communicate: string }>;
        };
    };
    order: {
        icons: {
            asc: JSX.Element;
            desc: JSX.Element;
        };
    };
    filter: {
        components: Record<string, IGridFilterComponent>;
        icons: {
            advancedEnable: JSX.Element;
            advancedDisable: JSX.Element;
            filter: JSX.Element;
            checked: JSX.Element;
            unchecked: JSX.Element;
            calendar: JSX.Element;
        };
    };
}
export interface IGridConfig {
    gridClassName: string;
}

export const GridContext = React.createContext<IGridStore>({
    gridClassName: "",
    locale: {
        apply: "apply",
        cancel: "cancel",
        noData: "noData",
        loading: "loading",
        filter: {
            like: "like",
            equals: "equals",
            differentThan: "differentThan",
            notLike: "notLike",
            startsWith: "startsWith",
            endsWith: "endsWith",
            addCondition: "addCondition",
            dateIsSet: "dateIsSet",
            dateIsNotSet: "dateIsNotSet",
            smaller: "smaller",
            smallerEqual: "smallerEqual",
            greater: "greater",
            greaterEqual: "greaterEqual",
            between: "between",
        },
    },
    persistStore: {
        set: () => false,
        get: <T,>(): T => null,
    },
    common: {
        icons: {
            delete: <></>,
        },
        components: {
            noData: (): React.ReactNode | null => null,
            loading: (): React.ReactNode | null => null,
        },
    },
    order: {
        icons: {
            asc: <></>,
            desc: <></>,
        },
    },
    filter: {
        components: {},
        icons: {
            advancedEnable: <></>,
            advancedDisable: <></>,
            filter: <></>,
            checked: <></>,
            unchecked: <></>,
            calendar: <></>,
        },
    },
});

export const useGridContext = () => useContext<IGridStore>(GridContext);
