import { authorizedJSONRequest } from "../auth/auth-api";

let debounceTimeout: ReturnType<typeof setTimeout>;
const toTranslate: string[] = [];
const toResolve: [CallableFunction, string][] = [];
const translationRegistry: Record<string, string> = {};

//let currLang = "pl";
let currentLang: string | null = null;

export const getCurrentLang = (): string => {
    if (currentLang !== null) {
        return currentLang;
    }
    currentLang = getLang();
    return currentLang;
};
const getLang = (): string => {
    const regex = /\/next\/(.{2})/;
    const match = window.location.href.match(regex);
    if (match && match[1] !== undefined) {
        return match[1];
    }

    const val = window.localStorage.getItem("lang");
    const data = val ? JSON.parse(val) : null;

    if (data !== null) {
        return data.lang;
    }

    return "en";
};

const callInitApiTranslations = async (lang: string) => {
    const translations = (await authorizedJSONRequest("/panel-translations/initial/" + lang)).data as Record<string, string>;
    console.log(translations, "Init translatios");
    Object.entries(translations).map(([text, translation]) => {
        translationRegistry[text] = translation;
    });
    return Promise.resolve();
};

const callApiForTranslations = async (toTranslate: string[], lang: string) => {
    const translations = (
        await authorizedJSONRequest("/panel-translations/get/" + lang, {
            toTranslate,
        })
    ).data as Record<string, string>;

    Object.entries(translations).map(([text, translation]) => {
        translationRegistry[text] = translation;
    });
    return Promise.resolve();
};

const getTranslationSync = (text: string, lang: null | string = null) => {
    if (lang === null) {
        lang = getCurrentLang();
    }
    if (lang === "pl") {
        return text;
    }

    if (translationRegistry[text]) {
        return translationRegistry[text];
    }

    getTranslation(text, lang);
    return "[T] " + text;
};

const getTranslation = async (text: string, lang?: string): Promise<string> => {
    if (lang === undefined) {
        lang = getCurrentLang();
    }

    if (lang === "pl") {
        return Promise.resolve(text);
    }

    if (toTranslate.includes(text)) {
        return Promise.resolve(text);
    }

    return new Promise((resolve, _reject) => {
        clearTimeout(debounceTimeout);
        toTranslate.push(text);
        toResolve.push([resolve, text]);
        debounceTimeout = setTimeout(async () => {
            await callApiForTranslations(toTranslate, lang ?? "en");
            toResolve.forEach(([_resolve, inText]) => {
                console.log("rozwiązuje " + inText + " " + translationRegistry[text]);
                _resolve(translationRegistry[inText] + "dupa");
            });
            toResolve.length = 0;
            toTranslate.length = 0;

            //resolve(null)
        }, 30);
    });
};

export { translationRegistry, getTranslation, callInitApiTranslations, getTranslationSync };
