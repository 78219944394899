import { useEffect, useState } from "react";

export const SelectTask = ({
    sendOption,
    sendInput,
    options,
    opt,
    input,
}: {
    sendOption: (value: string) => void;
    sendInput: (value: string) => void;
    options: string[];
    opt: string;
    input: string;
}) => {
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        if (opt && opt.length > 0) {
            setSelectedOption(opt);
        }
    }, [opt]);

    useEffect(() => {
        if (input && input.length > 0) {
            setInputValue(input);
        }
    }, [input]);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
        sendOption(event.target.value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
        sendInput(event.target.value);
    };

    return (
        <div className="flex flex-col gap-2">
            <select id="task-select" value={selectedOption} onChange={handleSelectChange} className="outline-none">
                <option value="" disabled>
                    Wybierz opcję...
                </option>
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <textarea placeholder="JSON object..." value={inputValue} onChange={handleInputChange} className="outline-none !h-auto !min-h-[32px] break-words" />
        </div>
    );
};
