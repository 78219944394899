import React from "react";
import { HotKeys } from "../HotKeys";
import { CommonIcons } from "../lib/CommonIcons";
import { IconType } from "../lib/IconType";
import { IPositionCalculatorOptions, Positioner, RelativePositionPresets } from "../Positioner";
import s from "./Modal.module.sass";

import { AnimatePresence, motion } from "motion/react";
export interface IModalProps {
    show: boolean;

    onShow?(): void;

    onHide?(): void;

    container?(): HTMLElement;

    recalculatePosition?: boolean;
    showHideLink?: boolean;
    title?: string;
    icon?: IconType;
    shadow?: boolean;
    layer?: boolean;
    width?: string | number;
    height?: string | number;
    draggable?: boolean;
    position?: {
        top?: string | number;
        left?: string | number;
        bottom?: string | number;
        right?: string | number;
    };
    className?: string;
    children: React.ReactNode;
    orientation?: string;
    relativeTo?(): HTMLElement | null | undefined;
    relativeSettings?: IPositionCalculatorOptions;
    animation?: string;
    hideOnBlur?: boolean;

    onOrientationChange?(type: string): void;
}

export class Modal extends React.Component<IModalProps> {
    public modalBody: HTMLDivElement | null = null;

    public static defaultProps = {
        show: false,
        draggable: false,
        recalculatePosition: true,
        shadow: true,
        layer: true,
        hideOnBlur: false,
        animation: "fade-in",
        relativeSettings: RelativePositionPresets.bottomLeft,
    };
    private modalContainer: HTMLDivElement | null = null;

    constructor(props: IModalProps) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(event: MouseEvent) {
        if (this.props.hideOnBlur && this.modalBody !== null && !this.modalBody.contains(event.currentTarget as Node)) {
            this.handleClose(event);
        }
    }

    public handleClose = (e: MouseEvent | null) => {
        if (e) {
            const target = e.target as HTMLDivElement;
            const rect = target.getBoundingClientRect();
            const scrollbarWidth = rect.width - target.clientWidth;
            const relativeMousePos = e.clientX - rect.left;
            //clicked outside of scroll bar
            if (!(rect.width - relativeMousePos < scrollbarWidth)) {
                if (e !== null) {
                    e.stopPropagation();
                }
            }
        }
        if (this.props.onHide) {
            this.props.onHide();
        }
    };

    public handleShow = () => {
        document.body.style.overflow = "hidden";
        if (this.props.onShow) {
            this.props.onShow();
        }
    };

    public UNSAFE_componentWillReceiveProps(nextProps: IModalProps) {
        if (this.props.show == false && nextProps.show == true) {
            this.handleShow();
        }
    }

    public componentDidMount() {
        //document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.show) {
            this.handleShow();
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    public render() {
        const p = this.props;

        let conf: { left?: string | number; right?: string | number; top?: string | number; bottom?: string | number } =
            {
                left: "50%",
                top: "50%",
            };

        if (p.position !== undefined) {
            conf = p.position;
            conf.left = p.position.left ? p.position.left : "auto";
            conf.top = p.position.top ? p.position.top : "auto";
        }

        const relativeSettings = p.relativeSettings;
        if (p.relativeTo) {
            conf = {};
        }

        const realativeTo = p.relativeTo ? p.relativeTo() : null;
        return (
            <AnimatePresence>
                {p.show ? (
                    <motion.div key="box">
                        {p.layer && (
                            <Positioner
                                animation={"fade-in"}
                                absoluteSettings={{ left: 0, top: 0, right: 0, bottom: 0 }}
                            >
                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                    <div
                                        style={{
                                            backgroundColor: p.shadow ? "rgba(0, 0, 0, 0.15)" : "transparent",
                                        }}
                                        ref={(el) => {
                                            this.modalContainer = el;
                                        }}
                                        className={s.container}
                                        onMouseDown={(e) => {
                                            this.handleClose(e.nativeEvent);
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        data-testid="modal-layer"
                                    />
                                </motion.div>
                            </Positioner>
                        )}

                        <Positioner
                            trackResize={this.props.recalculatePosition}
                            absoluteSettings={conf}
                            relativeTo={realativeTo ?? undefined}
                            relativeSettings={relativeSettings}
                            animation={this.props.animation}
                            container={
                                p.layer
                                    ? () => {
                                          return this.modalContainer as HTMLElement;
                                      }
                                    : undefined
                            }
                        >
                            <HotKeys
                                actions={[
                                    {
                                        key: "Escape",
                                        handler: () => {
                                            this.handleClose(null);
                                        },
                                    },
                                ]}
                                captureInput={true}
                                observeFromInput={["Escape"]}
                                autofocus={true}
                            >
                                <motion.div
                                    initial={{ scale: realativeTo ? 1 : 0.5, opacity: 0.3 }}
                                    animate={{
                                        scale: 1,
                                        opacity: 1,
                                        transition: { duration: 0.3 },
                                    }}
                                    exit={{ opacity: 0 }}
                                >
                                    <div
                                        className={p.className === undefined ? s.wModal : p.className}
                                        ref={(el) => {
                                            this.modalBody = el;
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            width: p.width ? p.width : "auto",
                                            height: p.height ? p.height : "auto",
                                            maxHeight:
                                                p.position &&
                                                p.position.top != "50%" &&
                                                p.position.top != "auto" &&
                                                p.position.top != undefined
                                                    ? `calc( 100vh - ${p.position.top}px - ${p.position.top}px )`
                                                    : "100vh",
                                        }}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();

                                            if (this.props.draggable) {
                                                document.onmousemove = (event) => {
                                                    event.preventDefault();
                                                    //modal.style.top = e.clientY + "px";
                                                    //modal.style.bottom = "auto";
                                                };
                                                document.onmouseup = () => {
                                                    document.onmouseup = null;
                                                    document.onmousemove = null;
                                                };
                                            }
                                        }}
                                        // onMouseUp={(e) => {
                                        //     if (this.props.draggable) {
                                        //         e.currentTarget.style.opacity = "1";
                                        //     }
                                        // }}
                                    >
                                        {p.showHideLink && (
                                            <a
                                                className={s.close}
                                                onClick={(e) => {
                                                    this.handleClose(e.nativeEvent);
                                                }}
                                            >
                                                <CommonIcons.close />
                                            </a>
                                        )}

                                        {p.title && (
                                            <div className={s.title}>
                                                {p.icon && <p.icon />} {p.title}
                                            </div>
                                        )}
                                        <div className={s.body + " h-full"}>{this.props.show ? p.children : null}</div>
                                    </div>
                                </motion.div>
                            </HotKeys>
                        </Positioner>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        );
    }
}
