"use client";
import { GridOptions } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";

export const Grid = (gridOptions: GridOptions) => {
    const [gridId, setGridId] = useState<string>("");
    const specialId: string = "";
    useEffect(() => {
        const tmp = "grid" + window.location.pathname.replace("next/", "").substring(3).replaceAll("/", "_") + (specialId !== "" ? "_" + specialId : "");
        setGridId(tmp);
        gridOptions = { ...gridOptions, gridId };
    }, []);

    if (gridId === "") {
        return null;
    }

    return (
        <div className="h-full ag-theme-quartz" onKeyUp={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <AgGridReact {...gridOptions} />
        </div>
    );
};
