"use client";
import { IToolPanelComp, IToolPanelParams } from "ag-grid-enterprise";

export class CustomToolPanel implements IToolPanelComp {
    eGui!: HTMLDivElement;
    init(_params: IToolPanelParams) {
        this.eGui = document.createElement("div");
        this.eGui.style.textAlign = "center";

        // calculate stats when new rows loaded, i.e. onModelUpdated

        this.eGui.innerHTML = this.genInnerHtml();
        this.eGui.querySelector(".btn")?.addEventListener("click", () => {
            localStorage.removeItem(_params.api.getGridId());
            window.location.reload();
        });
    }

    getGui() {
        return this.eGui;
    }

    refresh(): void {}

    genInnerHtml() {
        return `
          <div style="padding: 20px">
              <button class="btn btn-primary" >Ustawienia domyślne</button>
          </span>`;
    }
}
