import { CellClickedEvent, ColDef, ColDefField, ColGroupDef, ICellRendererParams, INumberFilterParams, ISetFilterParams, ITextFilterParams } from "ag-grid-enterprise";
import { Pencil } from "lucide-react";
import { BooleanFilter } from "./filters/BooleanFilter";

export type IOption = {
    value: string | number | boolean;
    label: string | number;
};

export class Columns {
    static group = <T,>(title: string, children: ColDef<T>[]): ColGroupDef<T> => {
        return {
            headerName: title,
            children: children,
        };
    };

    static edit = <T,>(onClick: (event: CellClickedEvent) => void, options: Partial<ColDef<T>> = {}): ColDef => {
        return {
            field: "id",
            headerName: "Edycja",
            headerComponent: () => <></>,
            suppressMenu: true,
            resizable: false,
            pinned: "right",
            cellClass: "text-center cursor-pointer hover:text-red-700 text-neutral-400",
            onCellClicked: (event: CellClickedEvent) => {
                onClick(event);
            },
            cellRenderer: () => <Pencil size={14} className="" />, //params: ICellRendererParams
            ...options,
        };
    };

    static number = <T,>(field: ColDefField<T, unknown>, headerName: string, options: Partial<ColDef<T>> = {}): ColDef<T> => {
        return {
            field,
            headerName,
            cellDataType: "number",
            filter: "agNumberColumnFilter",
            suppressMenu: true,
            suppressHeaderMenuButton: true,
            filterParams: {
                closeOnApply: true,
                buttons: ["apply", "cancel"],
            } as INumberFilterParams,
            ...options,
        };
    };

    static text = <T,>(field: ColDefField<T, unknown>, headerName: string, options: Partial<ColDef<T>> = {}): ColDef<T> => {
        return {
            field,
            headerName,
            cellDataType: "text",
            filter: "agTextColumnFilter",
            suppressHeaderMenuButton: true,
            filterParams: {
                closeOnApply: true,
                buttons: ["apply", "cancel"],
            } as ITextFilterParams,
            ...options,
        };
    };
    static boolean = <T,>(field: ColDefField<T, unknown>, headerName: string, options: Partial<ColDef<T>> = {}): ColDef<T> => {
        return {
            field,
            headerName,
            cellDataType: "boolean",
            filter: BooleanFilter,

            ...options,
        };
    };

    static map = <T,>(field: ColDefField<T>, values: IOption[], headerName: string, options: Partial<ColDef<T>> = {}): ColDef<T> => {
        return {
            field,
            headerName,
            cellDataType: "object",
            filter: "agSetColumnFilter",
            cellRenderer: (params: ICellRendererParams) => {
                const result = values.find((p) => p.value == params.value);
                return result?.label;
            },
            filterParams: {
                defaultToNothingSelected: true,
                values: values,
                valueFormatter: (params) => {
                    return params.value.label;
                },
                keyCreator: (params) => {
                    const value = params.value;
                    return value?.value;
                },
                closeOnApply: true,
                buttons: ["apply", "cancel"],
            } as ISetFilterParams<T, IOption>,
            ...options,
        };
    };
}
