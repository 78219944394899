// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProducers.proto" (package "grpcProducers", syntax proto3)
// tslint:disable
import { BoolValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcProducers.NullInt32
 */
export interface NullInt32 {
    /**
     * @generated from protobuf field: int64 Int32 = 1 [json_name = "Int32"];
     */
    Int32: number;
    /**
     * @generated from protobuf field: bool Valid = 2 [json_name = "Valid"];
     */
    Valid: boolean;
}
/**
 * @generated from protobuf message grpcProducers.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
/**
 * @generated from protobuf message grpcProducers.ListProducerValue
 */
export interface ListProducerValue {
    /**
     * @generated from protobuf field: repeated grpcProducers.Producer Value = 1 [json_name = "Value"];
     */
    Value: Producer[];
}
/**
 * @generated from protobuf message grpcProducers.GetProducersRow
 */
export interface GetProducersRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Type = 2 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Name = 3 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: int64 TecdocID = 4 [json_name = "TecdocID"];
     */
    TecdocID: number;
    /**
     * @generated from protobuf field: int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"];
     */
    TecdocIDManuf: number;
    /**
     * @generated from protobuf field: int64 SortPriority = 6 [json_name = "SortPriority"];
     */
    SortPriority: number;
    /**
     * @generated from protobuf field: int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"];
     */
    DisplayPrioryty: number;
    /**
     * @generated from protobuf field: int64 Trusted = 8 [json_name = "Trusted"];
     */
    Trusted: number;
    /**
     * @generated from protobuf field: double ScoringPriority = 9 [json_name = "ScoringPriority"];
     */
    ScoringPriority: number;
    /**
     * @generated from protobuf field: string ConnectedNames = 10 [json_name = "ConnectedNames"];
     */
    ConnectedNames: string;
    /**
     * @generated from protobuf field: string ConnectedTecdocNames = 11 [json_name = "ConnectedTecdocNames"];
     */
    ConnectedTecdocNames: string;
    /**
     * @generated from protobuf field: grpcProducers.NullInt32 Media = 12 [json_name = "Media"];
     */
    Media?: NullInt32;
}
/**
 * @generated from protobuf message grpcProducers.SortModel
 */
export interface SortModel {
    /**
     * @generated from protobuf field: string Sort = 1 [json_name = "Sort"];
     */
    Sort: string;
    /**
     * @generated from protobuf field: string ColId = 2 [json_name = "ColId"];
     */
    ColId: string;
}
/**
 * @generated from protobuf message grpcProducers.FilterConditionCl
 */
export interface FilterConditionCl {
    /**
     * @generated from protobuf field: string Filter = 1 [json_name = "Filter"];
     */
    Filter: string;
    /**
     * @generated from protobuf field: string FilterType = 2 [json_name = "FilterType"];
     */
    FilterType: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
}
/**
 * @generated from protobuf message grpcProducers.ListFilterModelValue
 */
export interface ListFilterModelValue {
    /**
     * @generated from protobuf field: repeated grpcProducers.FilterModel Value = 1 [json_name = "Value"];
     */
    Value: FilterModel[];
}
/**
 * @generated from protobuf message grpcProducers.AgGridQueryData
 */
export interface AgGridQueryData {
    /**
     * @generated from protobuf field: int64 StartRow = 1 [json_name = "StartRow"];
     */
    StartRow: number;
    /**
     * @generated from protobuf field: int64 EndRow = 2 [json_name = "EndRow"];
     */
    EndRow: number;
    /**
     * @generated from protobuf field: repeated grpcProducers.SortModel SortModel = 3 [json_name = "SortModel"];
     */
    SortModel: SortModel[];
    /**
     * @generated from protobuf field: repeated grpcProducers.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];
     */
    GoFilterModel: FilterModel[];
}
/**
 * @generated from protobuf message grpcProducers.AddCNInput
 */
export interface AddCNInput {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Name = 2 [json_name = "Name"];
     */
    Name: string;
}
/**
 * @generated from protobuf message grpcProducers.ListFilterConditionClValue
 */
export interface ListFilterConditionClValue {
    /**
     * @generated from protobuf field: repeated grpcProducers.FilterConditionCl Value = 1 [json_name = "Value"];
     */
    Value: FilterConditionCl[];
}
/**
 * @generated from protobuf message grpcProducers.ListSortModelValue
 */
export interface ListSortModelValue {
    /**
     * @generated from protobuf field: repeated grpcProducers.SortModel Value = 1 [json_name = "Value"];
     */
    Value: SortModel[];
}
/**
 * @generated from protobuf message grpcProducers.FilterModel
 */
export interface FilterModel {
    /**
     * @generated from protobuf field: string ColId = 1 [json_name = "ColId"];
     */
    ColId: string;
    /**
     * @generated from protobuf field: grpcProducers.FilterCondition Filter = 2 [json_name = "Filter"];
     */
    Filter?: FilterCondition;
}
/**
 * @generated from protobuf message grpcProducers.Producer
 */
export interface Producer {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Type = 2 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Name = 3 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: int64 TecdocID = 4 [json_name = "TecdocID"];
     */
    TecdocID: number;
    /**
     * @generated from protobuf field: int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"];
     */
    TecdocIDManuf: number;
    /**
     * @generated from protobuf field: int64 SortPriority = 6 [json_name = "SortPriority"];
     */
    SortPriority: number;
    /**
     * @generated from protobuf field: int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"];
     */
    DisplayPrioryty: number;
    /**
     * @generated from protobuf field: int64 Trusted = 8 [json_name = "Trusted"];
     */
    Trusted: number;
    /**
     * @generated from protobuf field: double ScoringPriority = 9 [json_name = "ScoringPriority"];
     */
    ScoringPriority: number;
    /**
     * @generated from protobuf field: string ConnectedNames = 10 [json_name = "ConnectedNames"];
     */
    ConnectedNames: string;
    /**
     * @generated from protobuf field: grpcProducers.NullInt32 Media = 11 [json_name = "Media"];
     */
    Media?: NullInt32;
    /**
     * @generated from protobuf field: repeated string ConnectedTecdocNames = 12 [json_name = "ConnectedTecdocNames"];
     */
    ConnectedTecdocNames: string[];
}
/**
 * @generated from protobuf message grpcProducers.ProducersGridResult
 */
export interface ProducersGridResult {
    /**
     * @generated from protobuf field: int64 Count = 1 [json_name = "Count"];
     */
    Count: number;
    /**
     * @generated from protobuf field: repeated grpcProducers.Producer Rows = 2 [json_name = "Rows"];
     */
    Rows: Producer[];
}
/**
 * @generated from protobuf message grpcProducers.FilterCondition
 */
export interface FilterCondition {
    /**
     * @generated from protobuf field: string Operator = 1 [json_name = "Operator"];
     */
    Operator: string;
    /**
     * @generated from protobuf field: repeated grpcProducers.FilterConditionCl Conditions = 2 [json_name = "Conditions"];
     */
    Conditions: FilterConditionCl[];
}
// @generated message type with reflection information, may provide speed optimized methods
class NullInt32$Type extends MessageType<NullInt32> {
    constructor() {
        super("grpcProducers.NullInt32", [
            { no: 1, name: "Int32", kind: "scalar", localName: "Int32", jsonName: "Int32", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Valid", kind: "scalar", localName: "Valid", jsonName: "Valid", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NullInt32>): NullInt32 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Int32 = 0;
        message.Valid = false;
        if (value !== undefined)
            reflectionMergePartial<NullInt32>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NullInt32): NullInt32 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Int32 = 1 [json_name = "Int32"];*/ 1:
                    message.Int32 = reader.int64().toNumber();
                    break;
                case /* bool Valid = 2 [json_name = "Valid"];*/ 2:
                    message.Valid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NullInt32, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Int32 = 1 [json_name = "Int32"]; */
        if (message.Int32 !== 0)
            writer.tag(1, WireType.Varint).int64(message.Int32);
        /* bool Valid = 2 [json_name = "Valid"]; */
        if (message.Valid !== false)
            writer.tag(2, WireType.Varint).bool(message.Valid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.NullInt32
 */
export const NullInt32 = new NullInt32$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcProducers.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProducerValue$Type extends MessageType<ListProducerValue> {
    constructor() {
        super("grpcProducers.ListProducerValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Producer }
        ]);
    }
    create(value?: PartialMessage<ListProducerValue>): ListProducerValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListProducerValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProducerValue): ListProducerValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProducers.Producer Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Producer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProducerValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProducers.Producer Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Producer.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ListProducerValue
 */
export const ListProducerValue = new ListProducerValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProducersRow$Type extends MessageType<GetProducersRow> {
    constructor() {
        super("grpcProducers.GetProducersRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "TecdocID", kind: "scalar", localName: "TecdocID", jsonName: "TecdocID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "TecdocIDManuf", kind: "scalar", localName: "TecdocIDManuf", jsonName: "TecdocIDManuf", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "SortPriority", kind: "scalar", localName: "SortPriority", jsonName: "SortPriority", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "DisplayPrioryty", kind: "scalar", localName: "DisplayPrioryty", jsonName: "DisplayPrioryty", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "Trusted", kind: "scalar", localName: "Trusted", jsonName: "Trusted", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "ScoringPriority", kind: "scalar", localName: "ScoringPriority", jsonName: "ScoringPriority", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "ConnectedNames", kind: "scalar", localName: "ConnectedNames", jsonName: "ConnectedNames", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "ConnectedTecdocNames", kind: "scalar", localName: "ConnectedTecdocNames", jsonName: "ConnectedTecdocNames", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "Media", kind: "message", localName: "Media", jsonName: "Media", T: () => NullInt32 }
        ]);
    }
    create(value?: PartialMessage<GetProducersRow>): GetProducersRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Type = "";
        message.Name = "";
        message.TecdocID = 0;
        message.TecdocIDManuf = 0;
        message.SortPriority = 0;
        message.DisplayPrioryty = 0;
        message.Trusted = 0;
        message.ScoringPriority = 0;
        message.ConnectedNames = "";
        message.ConnectedTecdocNames = "";
        if (value !== undefined)
            reflectionMergePartial<GetProducersRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProducersRow): GetProducersRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Type = 2 [json_name = "Type"];*/ 2:
                    message.Type = reader.string();
                    break;
                case /* string Name = 3 [json_name = "Name"];*/ 3:
                    message.Name = reader.string();
                    break;
                case /* int64 TecdocID = 4 [json_name = "TecdocID"];*/ 4:
                    message.TecdocID = reader.int64().toNumber();
                    break;
                case /* int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"];*/ 5:
                    message.TecdocIDManuf = reader.int64().toNumber();
                    break;
                case /* int64 SortPriority = 6 [json_name = "SortPriority"];*/ 6:
                    message.SortPriority = reader.int64().toNumber();
                    break;
                case /* int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"];*/ 7:
                    message.DisplayPrioryty = reader.int64().toNumber();
                    break;
                case /* int64 Trusted = 8 [json_name = "Trusted"];*/ 8:
                    message.Trusted = reader.int64().toNumber();
                    break;
                case /* double ScoringPriority = 9 [json_name = "ScoringPriority"];*/ 9:
                    message.ScoringPriority = reader.double();
                    break;
                case /* string ConnectedNames = 10 [json_name = "ConnectedNames"];*/ 10:
                    message.ConnectedNames = reader.string();
                    break;
                case /* string ConnectedTecdocNames = 11 [json_name = "ConnectedTecdocNames"];*/ 11:
                    message.ConnectedTecdocNames = reader.string();
                    break;
                case /* grpcProducers.NullInt32 Media = 12 [json_name = "Media"];*/ 12:
                    message.Media = NullInt32.internalBinaryRead(reader, reader.uint32(), options, message.Media);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProducersRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Type = 2 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Type);
        /* string Name = 3 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Name);
        /* int64 TecdocID = 4 [json_name = "TecdocID"]; */
        if (message.TecdocID !== 0)
            writer.tag(4, WireType.Varint).int64(message.TecdocID);
        /* int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"]; */
        if (message.TecdocIDManuf !== 0)
            writer.tag(5, WireType.Varint).int64(message.TecdocIDManuf);
        /* int64 SortPriority = 6 [json_name = "SortPriority"]; */
        if (message.SortPriority !== 0)
            writer.tag(6, WireType.Varint).int64(message.SortPriority);
        /* int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"]; */
        if (message.DisplayPrioryty !== 0)
            writer.tag(7, WireType.Varint).int64(message.DisplayPrioryty);
        /* int64 Trusted = 8 [json_name = "Trusted"]; */
        if (message.Trusted !== 0)
            writer.tag(8, WireType.Varint).int64(message.Trusted);
        /* double ScoringPriority = 9 [json_name = "ScoringPriority"]; */
        if (message.ScoringPriority !== 0)
            writer.tag(9, WireType.Bit64).double(message.ScoringPriority);
        /* string ConnectedNames = 10 [json_name = "ConnectedNames"]; */
        if (message.ConnectedNames !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.ConnectedNames);
        /* string ConnectedTecdocNames = 11 [json_name = "ConnectedTecdocNames"]; */
        if (message.ConnectedTecdocNames !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.ConnectedTecdocNames);
        /* grpcProducers.NullInt32 Media = 12 [json_name = "Media"]; */
        if (message.Media)
            NullInt32.internalBinaryWrite(message.Media, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.GetProducersRow
 */
export const GetProducersRow = new GetProducersRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SortModel$Type extends MessageType<SortModel> {
    constructor() {
        super("grpcProducers.SortModel", [
            { no: 1, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SortModel>): SortModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Sort = "";
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<SortModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SortModel): SortModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Sort = 1 [json_name = "Sort"];*/ 1:
                    message.Sort = reader.string();
                    break;
                case /* string ColId = 2 [json_name = "ColId"];*/ 2:
                    message.ColId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SortModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Sort = 1 [json_name = "Sort"]; */
        if (message.Sort !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Sort);
        /* string ColId = 2 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ColId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.SortModel
 */
export const SortModel = new SortModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterConditionCl$Type extends MessageType<FilterConditionCl> {
    constructor() {
        super("grpcProducers.FilterConditionCl", [
            { no: 1, name: "Filter", kind: "scalar", localName: "Filter", jsonName: "Filter", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "FilterType", kind: "scalar", localName: "FilterType", jsonName: "FilterType", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FilterConditionCl>): FilterConditionCl {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Filter = "";
        message.FilterType = "";
        message.Type = "";
        if (value !== undefined)
            reflectionMergePartial<FilterConditionCl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterConditionCl): FilterConditionCl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Filter = 1 [json_name = "Filter"];*/ 1:
                    message.Filter = reader.string();
                    break;
                case /* string FilterType = 2 [json_name = "FilterType"];*/ 2:
                    message.FilterType = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterConditionCl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Filter = 1 [json_name = "Filter"]; */
        if (message.Filter !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Filter);
        /* string FilterType = 2 [json_name = "FilterType"]; */
        if (message.FilterType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.FilterType);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.FilterConditionCl
 */
export const FilterConditionCl = new FilterConditionCl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterModelValue$Type extends MessageType<ListFilterModelValue> {
    constructor() {
        super("grpcProducers.ListFilterModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<ListFilterModelValue>): ListFilterModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterModelValue): ListFilterModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProducers.FilterModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProducers.FilterModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ListFilterModelValue
 */
export const ListFilterModelValue = new ListFilterModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgGridQueryData$Type extends MessageType<AgGridQueryData> {
    constructor() {
        super("grpcProducers.AgGridQueryData", [
            { no: 1, name: "StartRow", kind: "scalar", localName: "StartRow", jsonName: "StartRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "EndRow", kind: "scalar", localName: "EndRow", jsonName: "EndRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "SortModel", kind: "message", localName: "SortModel", jsonName: "SortModel", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel },
            { no: 4, name: "GoFilterModel", kind: "message", localName: "GoFilterModel", jsonName: "GoFilterModel", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<AgGridQueryData>): AgGridQueryData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.StartRow = 0;
        message.EndRow = 0;
        message.SortModel = [];
        message.GoFilterModel = [];
        if (value !== undefined)
            reflectionMergePartial<AgGridQueryData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgGridQueryData): AgGridQueryData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 StartRow = 1 [json_name = "StartRow"];*/ 1:
                    message.StartRow = reader.int64().toNumber();
                    break;
                case /* int64 EndRow = 2 [json_name = "EndRow"];*/ 2:
                    message.EndRow = reader.int64().toNumber();
                    break;
                case /* repeated grpcProducers.SortModel SortModel = 3 [json_name = "SortModel"];*/ 3:
                    message.SortModel.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcProducers.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];*/ 4:
                    message.GoFilterModel.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgGridQueryData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 StartRow = 1 [json_name = "StartRow"]; */
        if (message.StartRow !== 0)
            writer.tag(1, WireType.Varint).int64(message.StartRow);
        /* int64 EndRow = 2 [json_name = "EndRow"]; */
        if (message.EndRow !== 0)
            writer.tag(2, WireType.Varint).int64(message.EndRow);
        /* repeated grpcProducers.SortModel SortModel = 3 [json_name = "SortModel"]; */
        for (let i = 0; i < message.SortModel.length; i++)
            SortModel.internalBinaryWrite(message.SortModel[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcProducers.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"]; */
        for (let i = 0; i < message.GoFilterModel.length; i++)
            FilterModel.internalBinaryWrite(message.GoFilterModel[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.AgGridQueryData
 */
export const AgGridQueryData = new AgGridQueryData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCNInput$Type extends MessageType<AddCNInput> {
    constructor() {
        super("grpcProducers.AddCNInput", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddCNInput>): AddCNInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Name = "";
        if (value !== undefined)
            reflectionMergePartial<AddCNInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCNInput): AddCNInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Name = 2 [json_name = "Name"];*/ 2:
                    message.Name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCNInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Name = 2 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.AddCNInput
 */
export const AddCNInput = new AddCNInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterConditionClValue$Type extends MessageType<ListFilterConditionClValue> {
    constructor() {
        super("grpcProducers.ListFilterConditionClValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<ListFilterConditionClValue>): ListFilterConditionClValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterConditionClValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterConditionClValue): ListFilterConditionClValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProducers.FilterConditionCl Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterConditionClValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProducers.FilterConditionCl Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ListFilterConditionClValue
 */
export const ListFilterConditionClValue = new ListFilterConditionClValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSortModelValue$Type extends MessageType<ListSortModelValue> {
    constructor() {
        super("grpcProducers.ListSortModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel }
        ]);
    }
    create(value?: PartialMessage<ListSortModelValue>): ListSortModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSortModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSortModelValue): ListSortModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProducers.SortModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSortModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProducers.SortModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SortModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ListSortModelValue
 */
export const ListSortModelValue = new ListSortModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterModel$Type extends MessageType<FilterModel> {
    constructor() {
        super("grpcProducers.FilterModel", [
            { no: 1, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => FilterCondition }
        ]);
    }
    create(value?: PartialMessage<FilterModel>): FilterModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<FilterModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterModel): FilterModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ColId = 1 [json_name = "ColId"];*/ 1:
                    message.ColId = reader.string();
                    break;
                case /* grpcProducers.FilterCondition Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = FilterCondition.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ColId = 1 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ColId);
        /* grpcProducers.FilterCondition Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            FilterCondition.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.FilterModel
 */
export const FilterModel = new FilterModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Producer$Type extends MessageType<Producer> {
    constructor() {
        super("grpcProducers.Producer", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "TecdocID", kind: "scalar", localName: "TecdocID", jsonName: "TecdocID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "TecdocIDManuf", kind: "scalar", localName: "TecdocIDManuf", jsonName: "TecdocIDManuf", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "SortPriority", kind: "scalar", localName: "SortPriority", jsonName: "SortPriority", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "DisplayPrioryty", kind: "scalar", localName: "DisplayPrioryty", jsonName: "DisplayPrioryty", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "Trusted", kind: "scalar", localName: "Trusted", jsonName: "Trusted", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "ScoringPriority", kind: "scalar", localName: "ScoringPriority", jsonName: "ScoringPriority", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "ConnectedNames", kind: "scalar", localName: "ConnectedNames", jsonName: "ConnectedNames", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "Media", kind: "message", localName: "Media", jsonName: "Media", T: () => NullInt32 },
            { no: 12, name: "ConnectedTecdocNames", kind: "scalar", localName: "ConnectedTecdocNames", jsonName: "ConnectedTecdocNames", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Producer>): Producer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Type = "";
        message.Name = "";
        message.TecdocID = 0;
        message.TecdocIDManuf = 0;
        message.SortPriority = 0;
        message.DisplayPrioryty = 0;
        message.Trusted = 0;
        message.ScoringPriority = 0;
        message.ConnectedNames = "";
        message.ConnectedTecdocNames = [];
        if (value !== undefined)
            reflectionMergePartial<Producer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Producer): Producer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Type = 2 [json_name = "Type"];*/ 2:
                    message.Type = reader.string();
                    break;
                case /* string Name = 3 [json_name = "Name"];*/ 3:
                    message.Name = reader.string();
                    break;
                case /* int64 TecdocID = 4 [json_name = "TecdocID"];*/ 4:
                    message.TecdocID = reader.int64().toNumber();
                    break;
                case /* int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"];*/ 5:
                    message.TecdocIDManuf = reader.int64().toNumber();
                    break;
                case /* int64 SortPriority = 6 [json_name = "SortPriority"];*/ 6:
                    message.SortPriority = reader.int64().toNumber();
                    break;
                case /* int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"];*/ 7:
                    message.DisplayPrioryty = reader.int64().toNumber();
                    break;
                case /* int64 Trusted = 8 [json_name = "Trusted"];*/ 8:
                    message.Trusted = reader.int64().toNumber();
                    break;
                case /* double ScoringPriority = 9 [json_name = "ScoringPriority"];*/ 9:
                    message.ScoringPriority = reader.double();
                    break;
                case /* string ConnectedNames = 10 [json_name = "ConnectedNames"];*/ 10:
                    message.ConnectedNames = reader.string();
                    break;
                case /* grpcProducers.NullInt32 Media = 11 [json_name = "Media"];*/ 11:
                    message.Media = NullInt32.internalBinaryRead(reader, reader.uint32(), options, message.Media);
                    break;
                case /* repeated string ConnectedTecdocNames = 12 [json_name = "ConnectedTecdocNames"];*/ 12:
                    message.ConnectedTecdocNames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Producer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Type = 2 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Type);
        /* string Name = 3 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Name);
        /* int64 TecdocID = 4 [json_name = "TecdocID"]; */
        if (message.TecdocID !== 0)
            writer.tag(4, WireType.Varint).int64(message.TecdocID);
        /* int64 TecdocIDManuf = 5 [json_name = "TecdocIDManuf"]; */
        if (message.TecdocIDManuf !== 0)
            writer.tag(5, WireType.Varint).int64(message.TecdocIDManuf);
        /* int64 SortPriority = 6 [json_name = "SortPriority"]; */
        if (message.SortPriority !== 0)
            writer.tag(6, WireType.Varint).int64(message.SortPriority);
        /* int64 DisplayPrioryty = 7 [json_name = "DisplayPrioryty"]; */
        if (message.DisplayPrioryty !== 0)
            writer.tag(7, WireType.Varint).int64(message.DisplayPrioryty);
        /* int64 Trusted = 8 [json_name = "Trusted"]; */
        if (message.Trusted !== 0)
            writer.tag(8, WireType.Varint).int64(message.Trusted);
        /* double ScoringPriority = 9 [json_name = "ScoringPriority"]; */
        if (message.ScoringPriority !== 0)
            writer.tag(9, WireType.Bit64).double(message.ScoringPriority);
        /* string ConnectedNames = 10 [json_name = "ConnectedNames"]; */
        if (message.ConnectedNames !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.ConnectedNames);
        /* grpcProducers.NullInt32 Media = 11 [json_name = "Media"]; */
        if (message.Media)
            NullInt32.internalBinaryWrite(message.Media, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated string ConnectedTecdocNames = 12 [json_name = "ConnectedTecdocNames"]; */
        for (let i = 0; i < message.ConnectedTecdocNames.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.ConnectedTecdocNames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.Producer
 */
export const Producer = new Producer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProducersGridResult$Type extends MessageType<ProducersGridResult> {
    constructor() {
        super("grpcProducers.ProducersGridResult", [
            { no: 1, name: "Count", kind: "scalar", localName: "Count", jsonName: "Count", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Rows", kind: "message", localName: "Rows", jsonName: "Rows", repeat: 1 /*RepeatType.PACKED*/, T: () => Producer }
        ]);
    }
    create(value?: PartialMessage<ProducersGridResult>): ProducersGridResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Count = 0;
        message.Rows = [];
        if (value !== undefined)
            reflectionMergePartial<ProducersGridResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProducersGridResult): ProducersGridResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Count = 1 [json_name = "Count"];*/ 1:
                    message.Count = reader.int64().toNumber();
                    break;
                case /* repeated grpcProducers.Producer Rows = 2 [json_name = "Rows"];*/ 2:
                    message.Rows.push(Producer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProducersGridResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Count = 1 [json_name = "Count"]; */
        if (message.Count !== 0)
            writer.tag(1, WireType.Varint).int64(message.Count);
        /* repeated grpcProducers.Producer Rows = 2 [json_name = "Rows"]; */
        for (let i = 0; i < message.Rows.length; i++)
            Producer.internalBinaryWrite(message.Rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.ProducersGridResult
 */
export const ProducersGridResult = new ProducersGridResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterCondition$Type extends MessageType<FilterCondition> {
    constructor() {
        super("grpcProducers.FilterCondition", [
            { no: 1, name: "Operator", kind: "scalar", localName: "Operator", jsonName: "Operator", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Conditions", kind: "message", localName: "Conditions", jsonName: "Conditions", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<FilterCondition>): FilterCondition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Operator = "";
        message.Conditions = [];
        if (value !== undefined)
            reflectionMergePartial<FilterCondition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterCondition): FilterCondition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Operator = 1 [json_name = "Operator"];*/ 1:
                    message.Operator = reader.string();
                    break;
                case /* repeated grpcProducers.FilterConditionCl Conditions = 2 [json_name = "Conditions"];*/ 2:
                    message.Conditions.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterCondition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Operator = 1 [json_name = "Operator"]; */
        if (message.Operator !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Operator);
        /* repeated grpcProducers.FilterConditionCl Conditions = 2 [json_name = "Conditions"]; */
        for (let i = 0; i < message.Conditions.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Conditions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProducers.FilterCondition
 */
export const FilterCondition = new FilterCondition$Type();
/**
 * @generated ServiceType for protobuf service grpcProducers.ProducersService
 */
export const ProducersService = new ServiceType("grpcProducers.ProducersService", [
    { name: "AddConnectedTecDocName", options: {}, I: AddCNInput, O: BoolValue },
    { name: "GetProducers", options: {}, I: AgGridQueryData, O: ProducersGridResult },
    { name: "UpdateProducersRow", options: {}, I: GetProducersRow, O: BoolValue }
]);
