declare global {
    interface Window {
        addFetchListener: (listener: FetchListener) => void;
        removeFetchListener: (listener: FetchListener) => void;
    }
}

let connectionsCount = 0;

type CallType = "before" | "after" | "error";
type DataType = { input: RequestInfo | URL; init?: RequestInit } | Response | Error | null;
export type FetchListener = (type: CallType, data: DataType, connectionsCount: number) => void;

const fetchListeners: FetchListener[] = [];

const runListeners = (type: CallType, data: DataType, connectionsCount: number) => {
    fetchListeners.forEach((listener) => listener(type, data, connectionsCount));
};

const FetchProxy = async () => {
    window.addFetchListener = function (listener: FetchListener) {
        fetchListeners.push(listener);
    };
    window.removeFetchListener = function (listener: FetchListener) {
        const index = fetchListeners.indexOf(listener);
        if (index > -1) {
            fetchListeners.splice(index, 1);
        }
    };

    const originalFetch = window.fetch;
    window.fetch = async function (...args) {
        connectionsCount++;
        runListeners("before", { input: args[0], init: args[1] }, connectionsCount);
        let response;
        try {
            response = await originalFetch.apply(this, args);
        } catch (error) {
            connectionsCount--;
            runListeners("error", error as Error, connectionsCount);
            throw error;
        }
        connectionsCount--;
        runListeners("after", response, connectionsCount);
        return response;
    };
};

export const RegisterFetchListener = (listener: FetchListener) => {
    window.addFetchListener(listener);
};

export const RemoveFetchListener = (listener: FetchListener) => {
    window.removeFetchListener(listener);
};

export const AddOpenConnection = () => {
    connectionsCount++;
    runListeners("before", null, connectionsCount);
};

export const RemoveOpenConnection = () => {
    connectionsCount--;
    runListeners("after", null, connectionsCount);
};

export { FetchProxy };
