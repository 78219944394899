import styles from "./ProgressBar.module.sass";
const ProgressBar = ({ current, all, innerText }: { current: number; all: number; innerText?: string }) => {
    let percent = Math.round((current / all) * 100);
    if (percent === 100 && current !== all) {
        percent = 99;
    }
    return (
        <div className={styles.bar}>
            <div className={styles.progress} style={{ width: percent + "%" }}>
                {innerText ?? <>{percent} %</>}
            </div>
        </div>
    );
};

export { ProgressBar };
