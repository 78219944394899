import * as React from "react";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { BiCopy } from "@react-icons/all-files/bi/BiCopy";
import { BiExport } from "@react-icons/all-files/bi/BiExport";
import { BiImport } from "@react-icons/all-files/bi/BiImport";
import { BiPrinter } from "@react-icons/all-files/bi/BiPrinter";
import { BiUpload } from "@react-icons/all-files/bi/BiUpload";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { BsList } from "@react-icons/all-files/bs/BsList";
import { FiArrowDown } from "@react-icons/all-files/fi/FiArrowDown";
import { FiArrowUp } from "@react-icons/all-files/fi/FiArrowUp";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { FiLogOut } from "@react-icons/all-files/fi/FiLogOut";
import { HiOutlineDocumentText } from "@react-icons/all-files/hi/HiOutlineDocumentText";
import { HiOutlineFolder } from "@react-icons/all-files/hi/HiOutlineFolder";
import { HiOutlineSave } from "@react-icons/all-files/hi/HiOutlineSave";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import { MdModeEdit } from "@react-icons/all-files/md/MdModeEdit";
import { MdOpenInNew } from "@react-icons/all-files/md/MdOpenInNew";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";
import { RiAddFill } from "@react-icons/all-files/ri/RiAddFill";
import { RiDeleteBin4Line } from "@react-icons/all-files/ri/RiDeleteBin4Line";
import { RiHistoryFill } from "@react-icons/all-files/ri/RiHistoryFill";
import { RiShoppingCartLine } from "@react-icons/all-files/ri/RiShoppingCartLine";
import { VscAccount } from "@react-icons/all-files/vsc/VscAccount";
import { VscChevronDown } from "@react-icons/all-files/vsc/VscChevronDown";
import { VscChevronLeft } from "@react-icons/all-files/vsc/VscChevronLeft";
import { VscChevronRight } from "@react-icons/all-files/vsc/VscChevronRight";
import { VscChevronUp } from "@react-icons/all-files/vsc/VscChevronUp";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { MdOutlineLocalPhone } from "@react-icons/all-files/md/MdOutlineLocalPhone";
import { FaTasks } from "@react-icons/all-files/fa/FaTasks";
import { TbPlugConnected } from "@react-icons/all-files/tb/TbPlugConnected";
import { FaRegCommentAlt } from "@react-icons/all-files/fa/FaRegCommentAlt";
import { FaRegStickyNote } from "@react-icons/all-files/fa/FaRegStickyNote";
import { GrGallery } from "@react-icons/all-files/gr/GrGallery";

type IconType = React.JSXElementConstructor<{ style?: React.CSSProperties; className?: string }>;

type CommonIcons = {
    edit: IconType;
    delete: IconType;
    add: IconType;
    close: IconType;
    chevronDown: IconType;
    chevronUp: IconType;
    chevronLeft: IconType;
    chevronRight: IconType;
    download: IconType;
    upload: IconType;
    import: IconType;
    export: IconType;

    print: IconType;
    copy: IconType;
    openInNewWindow: IconType;
    search: IconType;
    check: IconType;
    up: IconType;
    down: IconType;
    filter: IconType;
    refresh: IconType;
    mail: IconType;
    folder: IconType;
    document: IconType;
    calendar: IconType;
    phone: IconType;
    plugConnected: IconType;
    taskSolid: IconType;
    comment: IconType;
    gallery: IconType;

    back: IconType;
    save: IconType;
    list: IconType;
    cart: IconType;
    clipboard: IconType;
    info: IconType;
    history: IconType;

    user: IconType;
    exit: IconType;
};

const CommonIcons: CommonIcons = {
    edit: MdModeEdit,
    delete: RiDeleteBin4Line,
    add: RiAddFill,
    close: VscChromeClose,
    chevronDown: VscChevronDown,
    chevronUp: VscChevronUp,
    chevronLeft: VscChevronLeft,
    chevronRight: VscChevronRight,
    download: FiDownload,
    upload: BiUpload,
    import: BiImport,
    export: BiExport,

    print: BiPrinter,
    copy: BiCopy,
    openInNewWindow: MdOpenInNew,
    search: AiOutlineSearch,
    check: MdDone,
    up: FiArrowUp,
    down: FiArrowDown,
    filter: BsFilter,
    refresh: MdRefresh,
    mail: AiOutlineMail,
    folder: HiOutlineFolder,
    document: HiOutlineDocumentText,
    calendar: BsCalendar,
    phone: MdOutlineLocalPhone,
    plugConnected: TbPlugConnected,
    taskSolid: FaTasks,
    comment: FaRegCommentAlt,
    gallery: GrGallery,

    back: VscChevronLeft,
    save: HiOutlineSave,
    list: BsList,
    cart: RiShoppingCartLine,
    clipboard: FaRegStickyNote,
    info: AiOutlineInfoCircle,
    history: RiHistoryFill,

    user: VscAccount,
    exit: FiLogOut,
};

export { CommonIcons };
