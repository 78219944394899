import React, { useEffect } from "react";

import { Monitor } from "../monitor/Monitor";
import { IMonitorData, MonitorWidget } from "../monitor/MonitorWidget";
import { GrpcManager } from "grpc-connect";

interface TaskMonitorProps {
    taskId: string;
    queue: string;
    showIndicator?: boolean;
    children?: (monitorData: IMonitorData) => React.ReactNode;
}

const TaskMonitor = ({ taskId, queue, showIndicator, children }: TaskMonitorProps) => {
    const streamMonitor = React.useRef(new Monitor());

    useEffect(() => {
        const abort = new AbortController();

        const monitor = GrpcManager.TaskMonitor.getTrack();

        streamMonitor.current.addCall(() => {
            return monitor.getTaskMonitorStream(
                {
                    Queue: queue,
                    TaskId: taskId,
                },
                { abort: abort.signal },
            );
        });

        return () => {
            abort.abort();
        };
    }, []);

    return <MonitorWidget observer={streamMonitor.current} title="Zadanie w toku" showIndicator={showIndicator} template={children}></MonitorWidget>;
};

export { TaskMonitor };
