import * as React from "react";
import styles from "./Tabs.module.sass";
import { CommonIcons } from "../lib/CommonIcons";
import { IconType } from "../lib/IconType";

type ITabsCallback = (index: number, e: any) => void;

interface ITabsProps {
    /**
     * On tab change
     */
    onTabChange?: ITabsCallback;
    /**
     * starting active tab
     */
    defaultActiveTab?: number;
    /**
     * Controled active tab
     */
    activeTab?: number;
    /**
     * All tabs will be mounted at component mount. Tab change will be faster.
     * Recommended only if needed.
     */
    mountAllTabs?: boolean;

    children: (React.ReactElement | false)[];

    className?: string;
}

interface ITabsState {
    currentTab: number;
}

export class Tabs extends React.Component<ITabsProps, ITabsState> {
    public static defaultProps: Partial<ITabsProps> = {
        mountAllTabs: false,
        defaultActiveTab: 0,
    };

    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            currentTab: (props.activeTab ? props.activeTab : props.defaultActiveTab) ?? 0,
        };
    }

    public handleTabChange(index: number, e: React.MouseEvent) {
        if (this.props.onTabChange) {
            this.props.onTabChange(index, e);
        }
        if (this.props.activeTab == null) {
            this.setState({ currentTab: index });
        }
    }

    // public UNSAFE_componentWillReceiveProps(nextProps: Readonly<ITabsProps>): void {
    //     if (nextProps.activeTab != null) {
    //         this.setState({ currentTab: nextProps.activeTab });
    //     }
    // }

    static getDerivedStateFromProps(props: Readonly<ITabsProps>, state: ITabsState) {
        if (props.activeTab !== undefined && props.activeTab !== state.currentTab) {
            return {
                ...state,
                currentTab: props.activeTab,
            };
        }
        return null;
    }

    public render() {
        const p = this.props;
        const s = this.state;

        return (
            <div className={"flex flex-col " + (this.props.className ? this.props.className : "")}>
                <div className={styles.tabsLinks}>
                    {React.Children.toArray(p.children.filter(Boolean)).map((child: any, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    (index == s.currentTab ? styles.active : "") +
                                    " transition-all text-sm  py-3 px-6 " +
                                    (child.props.badge ? "pr-2" : "")
                                }
                                onClick={this.handleTabChange.bind(this, index)}
                            >
                                {child.props.icon ? <child.props.icon /> : null}
                                {child.props.title}
                                {child.props.badge != undefined ? (
                                    <div
                                        className={
                                            "bg-neutral-200 rounded-md inline-block ml-1 text-xs px-1 align-[5px] text-black"
                                        }
                                    >
                                        {child.props.badge}
                                    </div>
                                ) : null}
                                {child.props.onClose && (
                                    <a
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            child.props.onClose(index);
                                        }}
                                        className={styles.tabsClose}
                                    >
                                        <CommonIcons.close />
                                    </a>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.tabsLinksSeparator} />

                {!this.props.mountAllTabs && React.Children.toArray(p.children)[s.currentTab]}

                {this.props.mountAllTabs && (
                    <div>
                        {React.Children.map(p.children, (child: any, index) => {
                            if (child == null) {
                                return;
                            }
                            return <div style={{ display: index == s.currentTab ? "block" : "none" }}>{child}</div>;
                        })}
                    </div>
                )}
            </div>
        );
    }
}

interface ITabPaneProps {
    /**
     * Tab title
     */
    title: string;
    /**
     * Tab badge
     */
    badge?: string | number;
    /**
     * Tab icon
     */
    icon?: IconType;

    /**
     * If specyfied tab will display close button
     * @param index
     */
    onClose?: (index: number) => any;
    children?: JSX.Element | JSX.Element[];
}

export const TabPane = (props: ITabPaneProps) => {
    return <div className="flex-grow">{props.children}</div>;
};
