import styles from "./LoadingIndicator.module.sass";

const LoadingIndicator = ({ size = 1, text = undefined }: { text?: string; size?: number }) => {
    return (
        <div className={styles.base}>
            <div>
                <span className={"size" + size}>
                    <i />
                    <i />
                    <i />
                    <i />
                </span>
                {text && <div className="w-loading-indicator-text">{text}</div>}
            </div>
        </div>
    );
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoadingIndicatorDots = ({ size = 1, text = undefined }: { text?: string; size?: number }) => {
    return (
        <div className={styles.dots}>
            <div className={styles.dotsGrid}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

const LoadingIndicatorFlat = () => {
    return (
        <div className={styles.flat}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export { LoadingIndicator, LoadingIndicatorDots, LoadingIndicatorFlat };
