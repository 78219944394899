import { useRef, useState } from "react";

import { getCurrentLang, getTranslation, translationRegistry } from "./GetTranslation";

const useTranslation = () => {
    const [, setRefreshTick] = useState(0);
    const ref = useRef<ReturnType<typeof setTimeout>>(null);

    //const lang = useMemo(() => getCurrentLang(), []);
    const lang = getCurrentLang();

    return (text: string) => {
        if (lang === "pl") {
            return text;
        }

        if (translationRegistry[text] !== undefined) {
            return translationRegistry[text];
        }
        getTranslation(text, lang as string).then(() => {
            /**
             * If component have many calls it prevent from many state changes, until last is translated
             */
            if (ref.current) clearTimeout(ref.current);

            // @ts-ignore read only property error prevention
            ref.current = setTimeout(() => {
                setRefreshTick((tick) => {
                    return tick + 1;
                });
            }, 20);
        });
        return "";
    };
};

export { useTranslation };
