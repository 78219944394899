import * as React from "react";
import { fI18n } from "../lib";
import { CommandMenu } from "../CommandMenu";
import s from "./CommandBar.module.sass";

import { CommonIcons } from "../lib/CommonIcons";
import { IconType } from "../lib/IconType";
import { Search } from "lucide-react";

export interface ICommand {
    key: string;
    icon?: IconType;
    label: string;
    onClick?: (event: React.MouseEvent, context: any) => any;
    subItems?: ICommand[];
}

interface IProps {
    isSearchBoxVisible?: boolean;
    searchPlaceholderText?: string;
    onSearch?: (value: string) => any;
    onSearchChange?: (value: string) => any;
    items: ICommand[];
    rightItems?: (ICommand | null | false)[];
    zIndex?: number;
    transparentBackground?: boolean;
}

export class CommandBar extends React.PureComponent<IProps> {
    public static defaultProps: Partial<IProps> = {
        isSearchBoxVisible: false,
        searchPlaceholderText: undefined,
        items: [],
        rightItems: [],
        transparentBackground: false,
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            searchedText: "",
        };
    }

    public handleExpandMenu = (_item: ICommand, _event: React.MouseEvent) => {};

    public handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchedText: event.target.value });
        if (this.props.onSearchChange) {
            this.props.onSearchChange(event.target.value);
        }
    };

    public handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode == 13) {
            if (this.props.onSearch) {
                this.props.onSearch((event.target as HTMLInputElement).value);
            }
        }
    };

    public render() {
        return (
            <div
                className={s.wCommandBar + " w-command-bar"}
                style={this.props.transparentBackground ? { backgroundColor: "transparent" } : {}}
            >
                {this.props.isSearchBoxVisible && (
                    <div className={s.searchBox}>
                        <Search />
                        <input
                            type="text"
                            onChange={this.handleSearchChange}
                            onKeyUp={this.handleSearchKeyDown}
                            placeholder={
                                this.props.searchPlaceholderText
                                    ? this.props.searchPlaceholderText
                                    : fI18n.t("frontend:search") + "..."
                            }
                            autoFocus={true}
                        />
                    </div>
                )}
                <div className={s.menuBar}>
                    <div className={s.buttonsLeft}>
                        {this.props.items.map((item: ICommand | false) => {
                            if (item !== null && item !== false) {
                                return (
                                    <React.Fragment key={item.key}>
                                        <CommandMenu items={item.subItems} activation={"hover"}>
                                            {(opened) => {
                                                return (
                                                    <a
                                                        onClick={(event) =>
                                                            item.subItems
                                                                ? this.handleExpandMenu(item, event)
                                                                : item.onClick && item.onClick(event, null)
                                                        }
                                                        className={opened ? s.elementOpened : ""}
                                                    >
                                                        {item.icon && <item.icon />} {item.label}
                                                        {item.subItems && <CommonIcons.chevronDown />}
                                                    </a>
                                                );
                                            }}
                                        </CommandMenu>
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </div>
                    {this.props.rightItems && this.props.rightItems.length > 0 ? (
                        <div className={s.buttonsRight}>
                            {this.props.rightItems.map((item) => {
                                if (item !== null && item !== false) {
                                    return (
                                        <React.Fragment key={item.key}>
                                            <CommandMenu items={item.subItems} activation={"hover"}>
                                                {(opened) => {
                                                    return (
                                                        <a
                                                            onClick={(event) =>
                                                                item.subItems
                                                                    ? this.handleExpandMenu(item, event)
                                                                    : item.onClick && item.onClick(event, null)
                                                            }
                                                            className={opened ? s.elementOpened : ""}
                                                        >
                                                            {item.icon && <item.icon />} {item.label}
                                                            {item.subItems && <CommonIcons.chevronDown />}
                                                        </a>
                                                    );
                                                }}
                                            </CommandMenu>
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
