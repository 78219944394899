import { GrpcManager, Types } from "grpc-connect";
const client = GrpcManager.QueueManager.getQueueService();
type TaskInfo = Types.QueueManager.QueueService.TaskInfo;
type GetQueueMessRow = Types.QueueManager.QueueService.GetQueueMessRow;

import React, { useEffect } from "react";
import { confirmDialog, TabPane, Tabs } from "serenity-controls";
import { TaskMonitor } from "./TaskMonitor";

const TaskDetails = ({ task }: { task: TaskInfo }) => {
    const [logs, setLogs] = React.useState<GetQueueMessRow[]>([]);
    const [errors, setErrors] = React.useState<GetQueueMessRow[]>([]);

    useEffect(() => {
        (async () => {
            const logs = await client.getQueueLogs({
                QueueName: task.Queue,
                TaskId: task.ID,
            });
            setLogs(logs.response.Value);

            const errors = await client.getQueueErrors({
                QueueName: task.Queue,
                TaskId: task.ID,
            });
            setErrors(errors.response.Value);
        })();
    }, []);

    return (
        <div style={{ padding: 0 }}>
            <Tabs>
                {task.State == 1 && (
                    <TabPane title="Progress">
                        <div style={{ padding: "5px" }}>
                            <TaskMonitor showIndicator={true} taskId={task.ID} queue={task.Queue} />
                        </div>

                        <button
                            style={{ width: "100%", color: "darkred" }}
                            onClick={async () => {
                                const confirm = await confirmDialog("Czy na pewno chcesz anulować zadanie?");
                                if (confirm) {
                                    await client.cancelTask({
                                        TaskID: task.ID,
                                        QueueName: task.Queue,
                                    });
                                }
                            }}
                        >
                            abort
                        </button>
                    </TabPane>
                )}
                {task.State != 1 && (
                    <TabPane title="Output">
                        <div style={{ padding: "0 5px" }}>
                            <b>Result</b>
                            <pre>{task.Result ? atob(task.Result) : "---"}</pre>
                            {logs.length > 0 ? (
                                <>
                                    <hr />
                                    <b>Zapisane logi</b>
                                    <pre>
                                        {logs.map((log, index) => (
                                            <React.Fragment key={index}>
                                                {log.Message}
                                                {"\n"}
                                            </React.Fragment>
                                        ))}
                                    </pre>
                                </>
                            ) : (
                                <></>
                            )}
                            {errors.length > 0 ? (
                                <>
                                    <hr />
                                    <b style={{ color: "darkred" }}>Zapisane błędy</b>
                                    {errors.map((error, index) => (
                                        <pre key={index}>{error.Message}</pre>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </TabPane>
                )}
                <TabPane title="Input">
                    <pre>{JSON.stringify(JSON.parse(atob(task.Payload)), null, 2)}</pre>
                </TabPane>
                <TabPane title="Task">
                    <pre>{JSON.stringify({ ...task, Payload: "-- see input tab --" }, null, 2)}</pre>
                </TabPane>
            </Tabs>
        </div>
    );
};

export { TaskDetails };
