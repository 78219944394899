import { ConfigKey, getEnv } from "@as-pl/env/src/getEnv";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { RpcTransport } from "@protobuf-ts/runtime-rpc";
import Cookies from "js-cookie";

export function getTransport(key: ConfigKey): RpcTransport {
    const url = getEnv(key);
    if (!url) {
        throw new Error(`Missing env variable: ${key}`);
    }
    const transport = new GrpcWebFetchTransport({
        baseUrl: url,
        meta: {
            authorization: "Bearer " + Cookies.get("jwt"),
        },
    });

    return transport;
}
