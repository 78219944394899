// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProducers.proto" (package "grpcProducers", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProducersService } from "./grpcProducers";
import type { GetProducersRow } from "./grpcProducers";
import type { ProducersGridResult } from "./grpcProducers";
import type { AgGridQueryData } from "./grpcProducers";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { AddCNInput } from "./grpcProducers";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcProducers.ProducersService
 */
export interface IProducersServiceClient {
    /**
     * @generated from protobuf rpc: AddConnectedTecDocName(grpcProducers.AddCNInput) returns (google.protobuf.BoolValue);
     */
    addConnectedTecDocName(input: AddCNInput, options?: RpcOptions): UnaryCall<AddCNInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetProducers(grpcProducers.AgGridQueryData) returns (grpcProducers.ProducersGridResult);
     */
    getProducers(input: AgGridQueryData, options?: RpcOptions): UnaryCall<AgGridQueryData, ProducersGridResult>;
    /**
     * @generated from protobuf rpc: UpdateProducersRow(grpcProducers.GetProducersRow) returns (google.protobuf.BoolValue);
     */
    updateProducersRow(input: GetProducersRow, options?: RpcOptions): UnaryCall<GetProducersRow, BoolValue>;
}
/**
 * @generated from protobuf service grpcProducers.ProducersService
 */
export class ProducersServiceClient implements IProducersServiceClient, ServiceInfo {
    typeName = ProducersService.typeName;
    methods = ProducersService.methods;
    options = ProducersService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddConnectedTecDocName(grpcProducers.AddCNInput) returns (google.protobuf.BoolValue);
     */
    addConnectedTecDocName(input: AddCNInput, options?: RpcOptions): UnaryCall<AddCNInput, BoolValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddCNInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProducers(grpcProducers.AgGridQueryData) returns (grpcProducers.ProducersGridResult);
     */
    getProducers(input: AgGridQueryData, options?: RpcOptions): UnaryCall<AgGridQueryData, ProducersGridResult> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AgGridQueryData, ProducersGridResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProducersRow(grpcProducers.GetProducersRow) returns (google.protobuf.BoolValue);
     */
    updateProducersRow(input: GetProducersRow, options?: RpcOptions): UnaryCall<GetProducersRow, BoolValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProducersRow, BoolValue>("unary", this._transport, method, opt, input);
    }
}
