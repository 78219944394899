"use client";

import { useEffect, useState } from "react";
import { GrpcManager, Types } from "grpc-connect";
import { Chain } from "./_components/Chain";

type TChain = Types.QueueManager.QueueServiceTaskChain.ChainDef;
type TChainLink = Types.QueueManager.QueueServiceTaskChain.ChainLinkDef;

export const TaskChainWidget = () => {
    const client = GrpcManager.QueueManager.getQueueServiceTaskChain();
    const qClient = GrpcManager.QueueManager.getQueueService();
    const [chain, setChain] = useState<TChain>({ ChainLinks: [{ Tasks: [] }] });
    const [queues, setQueues] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const q = await qClient.getQueuesInfo({});
                setQueues(Object.keys(q.response.Value).map((key) => q.response.Value[key]?.SystemName) as string[]);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    const updateTask = (chainLink: TChainLink, id: number) => {
        setChain((prev) => {
            const tmp = { ...prev };
            tmp.ChainLinks[id] = chainLink;
            return tmp;
        });
    };

    const addChainLink = () => {
        setChain((prev) => {
            const tmp = { ...prev };
            tmp.ChainLinks = [...tmp.ChainLinks, { Tasks: [] }];
            return tmp;
        });
    };

    const deleteChainLink = (id: number) => {
        setChain((prev) => {
            const tmp = { ...prev };
            tmp.ChainLinks = tmp.ChainLinks.filter((_, index) => index !== id);
            return tmp;
        });
    };

    return (
        <div className="w-[25rem] m-2">
            <div className="flex flex-col gap-2 mb-2">
                <button className="btn" onClick={addChainLink}>
                    Dodaj ogniwo
                </button>
                <button className="btn btn-primary" onClick={() => client.chainReport(chain)}>
                    Wyślij zadanie
                </button>
            </div>
            <div className="flex flex-col gap-2">
                {chain.ChainLinks.map((chainLink, id) => (
                    <Chain key={id} id={id} chainLink={chainLink} updateTask={updateTask} options={queues} deleteFn={deleteChainLink} />
                ))}
            </div>
        </div>
    );
};
