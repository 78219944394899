"use client";
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-enterprise/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import "ag-grid-enterprise/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import { AG_GRID_LOCALE_PL } from "@ag-grid-community/locale";
import {
    AgGridEvent,
    ColDef,
    ColDefField,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    ToolPanelDef,
} from "ag-grid-enterprise";

import { infoPanelGenerator } from "./TopPanel";
import { CustomToolPanel } from "./CustomToolPanel";
import { TGoFilter, translateFilter } from "./grpcTypes";

export type AgGridQueryData = IServerSideGetRowsRequest & { fields: string[]; goFilterModel: TGoFilter };

export const generateGridSystemId = (specialId: string = "") =>
    "grid" + window.location.pathname.replace("next/", "").substring(3).replaceAll("/", "_") + (specialId !== "" ? "_" + specialId : "");

function saveGridState(event: AgGridEvent) {
    const gridState = {
        columnState: event.api.getColumnState(),
        filterState: event.api.getFilterModel(),
        openPanel: event.api.getOpenedToolPanel(),
    };

    localStorage.setItem(event.api.getGridId(), JSON.stringify(gridState));
}
function getGridStateFromLocalStorage(gridSystemId: string) {
    const state = localStorage.getItem(gridSystemId);
    if (state) {
        const stateP = JSON.parse(state);
        console.log(stateP);
        return stateP;
    }
    return null;
}

function loadGridStateFromLocalStorage(gridOptions: GridReadyEvent) {
    const state = localStorage.getItem(gridOptions.api.getGridId());

    if (state) {
        const gridState = JSON.parse(state);
        console.log(gridState);
        gridOptions.api.applyColumnState({
            state: gridState.columnState,
            applyOrder: true,
        });
        gridOptions.api.setFilterModel(gridState.filterState);
        gridOptions.api.openToolPanel(gridState.openPanel);
    }
}

function getColumnsForSSR<T>(gridId: string, colDefs: ColDef<T>[]): [ColDefField<T, unknown>, string][] {
    const state = getGridStateFromLocalStorage(gridId) ?? { columnState: [] };

    const fields: [ColDefField<T, unknown>, string][] =
        colDefs
            .map((colDef) => {
                if (colDef instanceof Object && "field" in colDef && colDef.field) {
                    return [colDef.field as ColDefField<T, unknown>, typeof colDef.cellDataType === "string" ? colDef.cellDataType : "text"] as [ColDefField<T, unknown>, string];
                } else if (colDef instanceof Object && "children" in colDef) {
                    //todo grupowanie
                    console.error("TODO: grupowanie");
                    console.log(colDef.children);
                }
                return null;
            })
            .filter((field): field is [ColDefField<T, unknown>, string] => field !== null)
            .filter((field) => {
                return (
                    state.columnState.length === 0 ||
                    state.columnState.find((col: { colId: string; hide: boolean }) => {
                        return col.colId === field[0] && !col.hide;
                    })
                );
            }) ?? [];

    return fields;
}

export class AgConfigurator<T extends object> {
    private api: GridApi<T> | null = null;
    private hiddenColumns: ColDefField<T, unknown>[] = [];
    constructor(private options: GridOptions<T> = {}) {
        const columnsPanel: ToolPanelDef = {
            id: "columns",
            toolPanel: "agColumnsToolPanel",
            labelDefault: "Columns",
            iconKey: "columns",
            labelKey: "columns",

            toolPanelParams: {
                suppressValues: true,
                suppressPivots: true,
                suppressRowGroups: true,
                suppressPivotMode: true,
                suppressSideButtons: false,
                suppressColumnFilter: false,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
            },
        };

        const onGridReady = (params: GridReadyEvent<T>) => {
            params.api!.setGridOption("serverSideDatasource", this.options.serverSideDatasource);

            loadGridStateFromLocalStorage(params);

            // Pobierz kontener nagłówków
            const headerContainer = document.querySelector(`.ag-root-wrapper[grid-id="${params.api!.getGridId()}"]  .ag-header`);

            // Stwórz własny widget jako element DOM
            const myCustomWidget = document.createElement("div");
            // Wstaw widget poniżej nagłówków
            headerContainer?.parentNode?.insertBefore(myCustomWidget, headerContainer.nextSibling);
            infoPanelGenerator(params, myCustomWidget);

            // Zdarzenie, które nasłuchuje na zmiany filtrów
            params.api.addEventListener("filterChanged", function () {
                infoPanelGenerator(params, myCustomWidget);
            });
            params.api.addEventListener("sortChanged", function () {
                infoPanelGenerator(params, myCustomWidget);
            });
        };
        this.options = {
            gridId: generateGridSystemId(),
            localeText: AG_GRID_LOCALE_PL,
            onGridReady: onGridReady,
            onFirstDataRendered: (params) => {
                //params.api!.sizeColumnsToFit({})
                console.log("onFirstDataRendered");
                params.api!.autoSizeAllColumns();
            },
            onColumnVisible: (e) => {
                saveGridState(e);
                e.api.refreshServerSide({ purge: false });
            },
            onColumnResized: saveGridState,
            onColumnMoved: saveGridState,
            onSortChanged: saveGridState,
            onFilterChanged: saveGridState,
            onToolPanelVisibleChanged: saveGridState,
            paginationPageSizeSelector: [50, 100, 500, 1000],

            autoSizeStrategy: {
                type: "fitCellContents",
            },
            //cacheBlockSize: 100,
            //infiniteInitialRowCount: 1000,
            //maxBlocksInCache: 10,
            paginationPageSize: 50,
            rowBuffer: 50,
            //theme: myTheme,
            pagination: true,

            enableAdvancedFilter: false,
            rowModelType: "serverSide",
            sideBar: {
                toolPanels: [
                    "filters",
                    columnsPanel,
                    {
                        id: "customStats",
                        labelDefault: "Ustawienia",
                        labelKey: "customStats",
                        iconKey: "settings",
                        toolPanel: CustomToolPanel,
                        toolPanelParams: {
                            title: "Ustawienia",
                        },
                    },
                ],
                hiddenByDefault: false,
            },

            ...options,
        };
    }

    addGridId = (gridId: string) => {
        this.options.gridId = gridId;
        return this;
    };

    setColumns = (columns: ColDef<T>[], hiddenColumns: Array<ColDefField<T, unknown>> = []) => {
        this.hiddenColumns = hiddenColumns;
        this.options.columnDefs = columns.map((col) => {
            if (col.suppressHeaderMenuButton === undefined) {
                col.suppressHeaderMenuButton = true;
            }
            return col;
        });
        return this;
    };

    setDataSorce = (dataSource: (query: AgGridQueryData) => Promise<{ rows: T[]; count: number }>) => {
        const tmp: IServerSideDatasource = {
            getRows: async (params: IServerSideGetRowsParams<T>) => {
                const fields = getColumnsForSSR<T>(this.options.gridId ?? "grid id", this.options.columnDefs ?? []);
                const fieldsToServer = fields.map((f) => f[0]);

                this.hiddenColumns.forEach((f) => {
                    if (!fieldsToServer.includes(f)) {
                        fieldsToServer.push(f);
                    }
                });

                const query = {
                    ...params.request,
                    fields: fieldsToServer,
                    goFilterModel: translateFilter(params.request),
                };
                const result = await dataSource(query);

                params.success({
                    rowData: result.rows.map((row: T) => {
                        fields.forEach((def) => {
                            const field = def[0];
                            const cellDataType = def[1];

                            const fieldValue = getValueFromObject<T>(row, field);
                            switch (cellDataType) {
                                case "number":
                                    setValueOnObject(row, field, Number(fieldValue));
                                    break;
                                case "boolean":
                                    if (fieldValue === "0") {
                                        setValueOnObject(row, field, false);
                                    } else if (fieldValue === "1") {
                                        setValueOnObject(row, field, true);
                                    } else {
                                        setValueOnObject(row, field, Boolean(fieldValue));
                                    }
                                    break;
                            }
                        });

                        return row;
                    }),
                    rowCount: result.count,
                });
            },
        };

        this.options.serverSideDatasource = tmp;
        return this;
    };

    getConfig() {
        return this.options;
    }
}

function getValueFromObject<T extends object>(data: T, field: ColDefField<T, unknown>): unknown {
    if (!field.includes(".")) {
        //@ts-ignore If the field is a simple string, return the value directly
        return data[field as keyof T];
    }

    // Split the field path by dots to support nested properties
    const fieldParts = (field as string).split(".");

    // Traverse the object step by step
    // this is any couse it takes value from object and we dont know
    // at this point what is the type of this object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = data;
    for (const part of fieldParts) {
        value = value ? value[part] : undefined;
    }

    return value;
}

function setValueOnObject<T>(data: T, field: ColDefField<T>, value: unknown): void {
    if (!field.includes(".")) {
        //@ts-ignore If the field is a simple string, set the value directly
        data[field as keyof T] = value;
        return;
    }

    // Split the field path by dots to support nested properties
    const fieldParts = (field as string).split(".");

    // Traverse the object to find the correct place to set the value
    // this is any couse it takes value from object and we dont know
    // at this point what is the type of this object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let current: any = data;
    for (let i = 0; i < fieldParts.length - 1; i++) {
        const part = fieldParts[i] as string;

        // If current is not an object, initialize it as an empty object
        if (typeof current[part] !== "object" || current[part] === null) {
            current[part] = {};
        }

        current = current[part];
    }

    // Set the value at the final part of the path
    const lastPart: string = fieldParts[fieldParts.length - 1] as string;
    current[lastPart] = value;
}
