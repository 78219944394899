import { TDefaultFile, TThumbnailObj } from "../Types";
import { FileCard } from "./FileCard";
// import { createSwapy, SlotItemMap, Swapy } from "swapy";
// import { useEffect, useMemo, useRef, useState } from "react";

export const DisplayFiles = ({
    data,
    name,
    deleteFn,
    moveHandler,
    thumbnails,
}: {
    data: TDefaultFile[];
    name: string;
    deleteFn: (file: TDefaultFile) => void;
    moveHandler: (direction: number, key: string) => void;
    thumbnails?: TThumbnailObj[];
}) => {
    // const swapyRef = useRef<Swapy | null>(null);
    // const [files, setFiles] = useState<TDefaultFile[]>([]);

    // // * Slots map
    // const [slots, setSlots] = useState<SlotItemMap>([
    //     ...files.map((file) => ({ slotId: file.key, itemId: file.key })),
    //     { slotId: `${Math.round(Math.random() * 99999)}`, itemId: null },
    // ]);

    // // * Display files
    // const slottedFiles = useMemo(
    //     () => slots.map(({ slotId, itemId }) => ({ slotId, itemId, file: files.find((file) => file.key === itemId) })),
    //     [files, slots],
    // );

    // // * Init files
    // useEffect(() => {
    //     if (data && data.length) {
    //         setFiles(data);
    //     }
    // }, [data]);

    // // * Update slots
    // useEffect(() => {
    //     const newFiles = files
    //         .filter((file) => !slots.some((slotItem) => slotItem.itemId === file.key))
    //         .map((file) => ({
    //             slotId: file.key,
    //             itemId: file.key,
    //         }));

    //     const withoutRemoveFiles = slots.filter(
    //         (slotItem) => files.some((file) => file.key === slotItem.itemId) || !slotItem.itemId,
    //     );

    //     const updatedSlotItemsMap = [...withoutRemoveFiles, ...newFiles];

    //     setSlots(updatedSlotItemsMap);
    //     swapyRef.current?.setData({ array: updatedSlotItemsMap });
    // }, [files]);

    // // * Set Swapy ref
    // useEffect(() => {
    //     const container = document.querySelector(`.swapy_id`);
    //     console.log(`.${model + name}`);
    //     console.log(container);
    //     if (!container) return;
    //     swapyRef.current = createSwapy(container, {
    //         manualSwap: true,
    //     });

    //     swapyRef.current.onSwap(({ data }) => {
    //         console.log(data);
    //         swapyRef.current?.setData({ array: data.array });
    //         setSlots(data.array);
    //     });

    //     return () => {
    //         swapyRef.current?.destroy();
    //     };
    // }, []);

    return (
        <>
            <div className={`${"flex flex-wrap gap-2 mb-2"} ${"swapy_id"}`}>
                {/* {slottedFiles.map(({ itemId, slotId, file }) => (
                    <div key={slotId} data-swapy-slot={slotId}>
                        {file ? (
                            <FileCard
                                file={file}
                                itemId={itemId}
                                name={name}
                                deleteFn={deleteFn}
                                thumbnail={
                                    file.key && !file.uploaded && thumbnails
                                        ? thumbnails.find((thumb) => thumb.id === file.key)?.thumbnail
                                        : undefined
                                }
                            />
                        ) : null}
                    </div>
                ))} */}
                {data.map((file, index) => (
                    <div key={index} data-swapy-slot={index}>
                        {file ? (
                            <FileCard
                                file={file}
                                index={index}
                                isFirst={index === 0}
                                isLast={index === data.length - 1}
                                name={name}
                                deleteFn={deleteFn}
                                moveHandler={moveHandler}
                                thumbnail={
                                    file.key && !file.uploaded && thumbnails
                                        ? thumbnails.find((thumb) => thumb.id === file.key)?.thumbnail
                                        : undefined
                                }
                            />
                        ) : null}
                    </div>
                ))}
            </div>
        </>
    );
};
