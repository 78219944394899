import React from "react";
import styles from "./ErrorBoundary.module.sass";
import { BiSolidError } from "@react-icons/all-files/bi/BiSolidError";

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(_error: Error): Partial<ErrorBoundaryState> {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error("Uncaught error:", error, errorInfo);
        this.setState({
            error,
            errorInfo,
        });
    }

    handleReset = () => {
        this.setState({
            hasError: false,
            error: null,
            errorInfo: null,
        });
    };

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.main}>
                    <div className={styles.title + " flex"}>
                        <div>
                            <h1>
                                <BiSolidError />
                                Something went wrong.
                            </h1>
                        </div>
                        <div>
                            <button onClick={this.handleReset}>Try Again</button>
                        </div>
                    </div>

                    <pre>{this.state.error?.message}</pre>
                    <pre>{this.state.error?.stack}</pre>
                    {/* <hr />
          <pre>{JSON.stringify(this.state.errorInfo, null, 2)}</pre> */}
                </div>
            );
        }

        return this.props.children;
    }
}
