export enum ConfigKey {
    NEXT_PUBLIC_API_HOST = "NEXT_PUBLIC_API_HOST",
    NEXT_PUBLIC_FRONT_DOMAIN = "NEXT_PUBLIC_FRONT_DOMAIN",
    NEXT_PUBLIC_RPC_BACKEND = "NEXT_PUBLIC_RPC_BACKEND",
    NEXT_PUBLIC_BACKEND = "NEXT_PUBLIC_BACKEND",
    QUEUE_API = "QUEUE_API",
    USER_NOTIFICATION_SERVICE = "USER_NOTIFICATION_SERVICE",
    ACCESSIBLE_FILES_SERVICE = "ACCESSIBLE_FILES_SERVICE",
    NEXT_PUBLIC_OFFER_SERVICE = "NEXT_PUBLIC_OFFER_SERVICE",
    NEXT_____DEPRECATED_____PUBLIC_QUEUE_API = "NEXT_PUBLIC_QUEUE_API",
    NEXT_PUBLIC_FILES_STORAGE_API = "NEXT_PUBLIC_FILES_STORAGE_API",
    NEXT_PUBLIC_MEDIA_SERVER_ADDRESS = "NEXT_PUBLIC_MEDIA_SERVER_ADDRESS",
    NEXT_PUBLIC_QUEUE_MANAGER = "NEXT_PUBLIC_QUEUE_MANAGER",
    NEXT_PUBLIC_TEC_DOC_IMPORTER_WEB = "NEXT_PUBLIC_TEC_DOC_IMPORTER_WEB",
}

declare type Tprocess = { env: Record<string, string> };
declare const process: Tprocess;

export const getEnv = (key: ConfigKey): string | undefined => {
    if (key === undefined) {
        console.log("key is undefined");
        console.trace();
    }

    let storage;
    let source = "_none";
    //used for old panel where env is stored in window object
    //@ts-ignore this is how we can access window object in nodejs

    if (typeof window !== "undefined" && typeof window["__ENV"] !== "undefined") {
        //@ts-ignore this is how we can access window object in nodejs
        storage = window["__ENV"];
        source = "erp-front";
    } else if (typeof process !== "undefined" && typeof process.env !== "undefined") {
        storage = process.env;
        source = "erp-next-backend";
    }

    if (typeof storage === "undefined") {
        console.log("storage is undefined " + source);
        console.trace();
        return undefined;
    }
    if (typeof storage[key] === "undefined") {
        console.log("Missing env variable: " + key + " in " + source);
        return undefined;
    }

    return storage[key];
};
