import React from "react";
import { IGroupByData } from "../../../interfaces/IGroupByData";

export const GridGroupRow = ({
    columnsLength,
    rowToOutput,
    groupData,
}: {
    columnsLength: number;
    rowToOutput: JSX.Element;
    groupData: { label: string | JSX.Element }[];
}) => {
    return (
        <React.Fragment>
            <div
                style={{
                    gridColumn: "span " + columnsLength,
                    backgroundColor: "grey",
                    color: "white",
                    padding: 2,
                    fontSize: 12,
                }}
            >
                {groupData.map((el, index) => (
                    <React.Fragment key={index}>{el.label}</React.Fragment>
                ))}
            </div>
            {rowToOutput}
        </React.Fragment>
    );
};

export const groupByGetInfo = <Row,>(
    row1: Row | null,
    row2: Row,
    groupBy: IGroupByData<Row>[],
): { label: string | JSX.Element }[] => {
    const info: { label: string | JSX.Element }[] = [];

    for (const group of groupBy) {
        if (group.field !== undefined) {
            if (row1 == null || typeof row1 == "undefined" || row1[group.field] !== row2[group.field]) {
                info.push({ label: row2[group.field] as string | JSX.Element });
            }
        } else if (group.equalizer !== undefined) {
            if (row1 == null || typeof row1 == "undefined" || group.equalizer(row1, row2)) {
                info.push({ label: group.labelProvider ? group.labelProvider(row2, row1) : "" });
            }
        }
    }

    return info;
};
