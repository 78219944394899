import { ConfigKey, getEnv } from "@as-pl/env/src/getEnv";
import { Flag, Phone, User } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { ColMap, ColText, FullGrid, IFullGridDataQueryParams, LoadingIndicatorFlat, Modal } from "serenity-controls";
import { authorizedJSONRequest, getUser, logout } from "../../auth";
import { FetchListener, RegisterFetchListener, RemoveFetchListener } from "../../tools/FetchProxy";
import { getCurrentLang } from "../../translation";

const Top = () => {
    const user = getUser();
    const currLang = getCurrentLang();
    const [isContactVisible, setContactVisible] = useState(false);

    return (
        <>
            <div className={"bg-neutral-800 flex place-items-center items-stretch h-[45px] w-full"}>
                <div
                    className="text-white p-1  w-[61px] text-center cursor-pointer transition-colors duration-300 hover:bg-neutral-600 content-center"
                    onClick={() => {
                        window.location.href = getEnv(ConfigKey.NEXT_PUBLIC_FRONT_DOMAIN) + "/next/" + currLang;
                    }}
                >
                    <img src={getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) + "/resources/img/logo_common.webp"} style={{ height: 23 }} alt="logo" className="" />
                </div>
                <div className="grow"></div>
                <div className="content-center">
                    <FetchIndicator />
                </div>
                <div className="flex flex-row items-stretch text-white hover:children:bg-zinc-600 children:cursor-pointer children:content-center children:pl-3 children:pr-3 children:transition-colors children:duration-300 last:pr-5 ">
                    <div
                        className="pr-5"
                        onClick={async () => {
                            setContactVisible(!isContactVisible);
                        }}
                    >
                        <span className="mr-3 text-neutral-400">
                            <Phone />
                        </span>
                        Kontakt
                    </div>
                    <div>
                        {["pl", "en"]
                            .filter((el) => el != currLang)
                            .map((lang: string) => (
                                <a
                                    key={lang}
                                    className="content-center text-white uppercase hover:text-white"
                                    onClick={async () => {
                                        await authorizedJSONRequest("/admin/changeLang/" + (currLang === "pl" ? "en" : "pl"));
                                        if (window.location.href.includes("/next")) {
                                            window.location.href = window.location.href.replace("/" + currLang, "/" + (currLang === "pl" ? "en" : "pl"));
                                        } else {
                                            window.location.reload();
                                        }
                                    }}
                                >
                                    <span className="mr-3 text-neutral-400">
                                        <Flag />
                                    </span>
                                    {lang}
                                </a>
                            ))}
                    </div>

                    <div
                        className="pr-5"
                        onClick={async () => {
                            await logout();
                            window.location.href = getEnv(ConfigKey.NEXT_PUBLIC_FRONT_DOMAIN) + "/next/" + currLang + "/account/login";
                        }}
                    >
                        <span className="mr-3 text-neutral-400">
                            <User />
                        </span>
                        {user?.login}
                    </div>
                </div>
            </div>
            {isContactVisible && (
                <Modal show={true} onHide={() => setContactVisible(false)} showHideLink={true}>
                    <div className="w-[80vw] h-[90vh]">
                        <Contacts />
                    </div>
                </Modal>
            )}
        </>
    );
};

const FetchIndicator = () => {
    const [connections, setConnections] = useState(0);

    const updater = useRef<FetchListener>((_stage, _data, activeConnections) => {
        setConnections(activeConnections);
    });

    useEffect(() => {
        RegisterFetchListener(updater.current);
        return () => {
            RemoveFetchListener(updater.current);
        };
    }, []);
    return (
        <div className={"transition-opacity duration-500 opacity-0  " + (connections > 0 ? " opacity-100" : "")}>
            <LoadingIndicatorFlat />
        </div>
    );
};

export interface ProfilesListResponse {
    rows: ProfileRow[];
    count: number;
}

export interface ProfileRow {
    id: string;
    sort?: string;
    group: string;
    position: string;
    name: string;
    email: string;
    phone_number: string;
    cell_number: string;
    skype: string;
    user_id: string;
    languages: string;
}

export interface GroupsResponse {
    groups: { [key: string]: string };
}

export const queryProfileList = async (queryData: IFullGridDataQueryParams): Promise<ProfilesListResponse> => {
    return (await authorizedJSONRequest<ProfilesListResponse>("/erp/employees/profile/list", { queryData })).data;
};

export const queryGroupsList = async (): Promise<GroupsResponse> => {
    return (await authorizedJSONRequest<GroupsResponse>("/erp/employees/profile/index")).data;
};

const Contacts = () => {
    const [groups, setGroups] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        queryGroupsList().then((r) => {
            setGroups(Object.entries(r.groups).map(([key, val]) => ({ value: key, label: val })));
        });
    }, []);

    if (groups.length == 0) {
        return null;
    }
    return (
        <>
            <FullGrid<ProfileRow>
                dataProvider={(queryData) => {
                    queryData.fields = [...queryData.fields, "id", "user_id"];

                    queryData.filters = [
                        {
                            field: "group",
                            value: [
                                {
                                    value: "",
                                    labelValue: "",
                                    condition: "!=",
                                    labelCondition: "",
                                    operator: "or",
                                },
                            ],
                        },
                    ];

                    return queryProfileList(queryData);
                }}
                onPage={500}
                gridProps={{ showFooter: false }}
                columns={[
                    ColMap.create("group", groups, "Dział"),
                    ColText.create("position", "Stanowisko"),
                    //Column.text("region", "Region"),
                    ColText.create("name", "Imię i nazwisko"),
                    ColText.create<ProfileRow>("email", "Email")
                        .className(["right"])
                        .addTemplate(({ row }) => {
                            return row.email && <a href={"mailto: " + row["email"]}>{row["email"]}</a>;
                        }),
                    //.addTemplate(gridTemplateCuttedColumn({ copyable: true })),
                    ColText.create<ProfileRow>("phone_number", "Telefon").width("max-content").className(["right"]),
                    ColText.create<ProfileRow>("cell_number", "Komórka").width("max-content").className(["right"]),
                    ColText.create<ProfileRow>("skype", "Skype")
                        .className(["right"])
                        .addTemplate(({ row }) => <a href={"skype: " + row["skype"]}>{row["skype"]}</a>),
                    ColText.create<ProfileRow>("languages", "Języki").width("min-content"),
                ]}
            />
        </>
    );
};

export { Top };
