import { Modal } from "../Modal";
import * as React from "react";
import { createRoot } from "react-dom/client";

import { IPositionCalculatorOptions, RelativePositionPresets } from "../Positioner";
import { CommonIcons } from "../lib/CommonIcons";
import { IOption } from "../fields";
import FocusLock from "react-focus-lock";
import styles from "./ConfirmDialog.module.sass";

export interface IConfirmDialogCompProps {
    title: string;
    question: string;
    options: IOption[];
    onSelect: (value: string | number | boolean) => void;
    onAbort?: () => void;
    layer?: boolean;
    relativeTo?: HTMLElement;
    relativeSettings?: IPositionCalculatorOptions;
}
const ConfirmDialogComp = ({
    title,
    question,
    options,
    onSelect,
    layer,
    relativeTo,
    relativeSettings,
    onAbort,
}: IConfirmDialogCompProps) => {
    relativeSettings = relativeSettings ?? RelativePositionPresets.bottomRight;

    return (
        <Modal
            show={true}
            title={title}
            showHideLink={title ? true : false}
            relativeTo={relativeTo ? () => relativeTo : undefined}
            shadow={relativeTo ? false : true}
            relativeSettings={relativeSettings}
            layer={layer ?? true}
            icon={CommonIcons.info}
            onHide={() => {
                if (onAbort) {
                    onAbort();
                }
            }}
        >
            <div className={styles.main}>
                <div style={{ padding: 10 }}>{question}</div>
                <FocusLock autoFocus={true}>
                    <div style={{ padding: 5, textAlign: "right" }}>
                        {options.map((el) => (
                            <button
                                key={el.value + ""}
                                onClick={() => {
                                    onSelect(el.value);
                                }}
                            >
                                {el.label}
                            </button>
                        ))}
                    </div>
                </FocusLock>
            </div>
        </Modal>
    );
};

export { ConfirmDialogComp };

export const confirmDialog = async (
    message: string,
    options: Partial<Omit<IConfirmDialogCompProps, "onAbort">> = {},
) => {
    return new Promise((resolve) => {
        const wrapper = document.body.appendChild(document.createElement("div"));

        const cleanup = () => {
            root.unmount();
            wrapper.remove();
        };

        const x: React.ReactElement = (
            <ConfirmDialogComp
                title={"confirm"}
                question={message}
                onSelect={(val) => {
                    cleanup();
                    resolve(val);
                }}
                onAbort={() => {
                    cleanup();
                    resolve(undefined);
                }}
                options={[
                    { value: true, label: "yes" },
                    { value: false, label: "no" },
                ]}
                {...options}
            />
        );
        const root = createRoot(wrapper);
        root.render(x);
    });
};
