// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProductimageexporter.proto" (package "grpcProductimageexporter", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProductImageExporterService } from "./grpcProductimageexporter";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { Input } from "./grpcProductimageexporter";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcProductimageexporter.ProductImageExporterService
 */
export interface IProductImageExporterServiceClient {
    /**
     * @generated from protobuf rpc: AddToQueue(grpcProductimageexporter.Input) returns (google.protobuf.BoolValue);
     */
    addToQueue(input: Input, options?: RpcOptions): UnaryCall<Input, BoolValue>;
}
/**
 * @generated from protobuf service grpcProductimageexporter.ProductImageExporterService
 */
export class ProductImageExporterServiceClient implements IProductImageExporterServiceClient, ServiceInfo {
    typeName = ProductImageExporterService.typeName;
    methods = ProductImageExporterService.methods;
    options = ProductImageExporterService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddToQueue(grpcProductimageexporter.Input) returns (google.protobuf.BoolValue);
     */
    addToQueue(input: Input, options?: RpcOptions): UnaryCall<Input, BoolValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Input, BoolValue>("unary", this._transport, method, opt, input);
    }
}
